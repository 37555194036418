import Button from '@sportnet/ui/Button';
import ResponsiveMenu from '../../components/ResponsiveMenu';
import { mb, mediaSizes } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';
import config from '../../config';
import __ from '../../utilities/__';
import fluidTypography from '../../utilities/fluidTypography';
import MaxWidthBox from '../../components/MaxWidthBox';

const Burger = styled.div`
button {
  background: transparent !important;
  border: 0;
}
display: flex;
align-items: center;
  ${mb('l')} {
    display: none;
  }}
`;

const MenuItem = styled.li`
  padding: ${rem(8)} ${rem(16)};
  margin: 0;
  text-align: center;
  font-weight: bold;
  ${fluidTypography(14, 18, mediaSizes.l, 1920)};
  a {
    :hover {
      text-decoration: underline;
    }
    color: #000;
  }
`;

const FullWidthBox = styled.div`
  background: #ffd150;
  position: relative;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${rem(config.MOBILE_HEADER_HEIGHT)};
  ${mb('l')} {
    height: ${rem(config.HEADER_HEIGHT)};
  }
  padding: ${rem(8)} 0;
`;

const MobileLogoWrapper = styled.div`
  display: flex;
  margin-right: ${rem(8)};
  img {
    height: ${rem(40)} !important;
    width: auto;
  }
  ${mb('l')} {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  max-width: 50%;
  z-index: 61;
  display: flex;
  align-items: center;
  img {
    height: ${rem(80)};
    ${mb('l')} {
      height: ${rem(120)};
    }
    width: auto;
  }
  > a {
    margin-bottom: -${rem(40)};
  }
`;
const MenuWrapper = styled.div`
  display: none;
  ${mb('l')} {
    display: inherit;
  }}
`;
const Menu = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
`;

const OffsetedLogosWrapper = styled.div`
  display: none;
  ${mb('xxxl')} {
    display: flex;
  }
  position: absolute;
  top: 0;
  left: ${rem(48)};
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  width: calc((100% - ${rem(config.CONTENT_MAX_WIDTH)}) / 2);
  > img {
    width: auto;
    height: ${rem(48)};
    margin: 0 ${rem(4)};
  }
`;

interface Section {
  name: string;
  _id: string;
  url?: string;
  sections?: Section[];
}

const Header: React.FC<RouteComponentProps<{}, {}>> = (props) => {
  const [menuVisible, setMenuVisible] = React.useState(false);

  const renderLink = (renderProps: {
    section: Section;
    linkProps: {
      className: string;
      onClick: () => void;
      children: React.ReactNode;
      url: string;
    };
    level: number;
  }) => {
    const { linkProps, section } = renderProps;
    if (!section.url?.startsWith('/')) {
      return (
        <a
          className="responsive-menu-link"
          href={section.url || ''}
          key={section._id}
        >
          {linkProps.children}
        </a>
      );
    }
    return (
      <Link to={section.url || ''} {...linkProps}>
        {linkProps.children}
      </Link>
    );
  };

  const menuItems: Section[] = [
    { name: __('Zapojené školy'), _id: 'schools', url: '/skoly' },
    {
      name: __('Vzdelávanie'),
      _id: 'courses',
      url: 'https://futbalsfz.sk/treneri/skolenia/sfz-grassroots-leader/',
    },
    {
      name: __('Dokumenty'),
      _id: 'trainers',
      url: 'https://www.futbalsfz.sk/mladez-a-rozvoj/grassroots/dajmespolugol/dokumenty',
    },
    {
      name: __('Fanshop'),
      _id: 'fanshop',
      url: 'https://fanshop.futbalsfz.sk/kategoria/dajme-spolu-gol',
    },
    { name: __('Správa školy'), _id: 'admin', url: '/admin' },
  ];

  return (
    <FullWidthBox>
      <OffsetedLogosWrapper>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://futbalsfz.sk"
        >
          <img
            alt="sfz"
            src="https://api.sportnet.online/data/ppo/futbalsfz.sk/logo"
            width={48}
            height={48}
          />
        </a>
      </OffsetedLogosWrapper>
      <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
        <Wrapper>
          <LogoWrapper>
            <MobileLogoWrapper>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://futbalsfz.sk"
              >
                <img
                  alt="sfz"
                  src="https://api.sportnet.online/data/ppo/futbalsfz.sk/logo"
                  width={40}
                  height={40}
                />
              </a>
            </MobileLogoWrapper>
            <Link to="/">
              <img alt="logo" src="/logo.png" />
            </Link>
          </LogoWrapper>
          <MenuWrapper>
            <Menu>
              {menuItems.map((i) => {
                if (i.url?.startsWith('/')) {
                  return (
                    <MenuItem key={i._id}>
                      <Link to={i.url || ''} key={i._id}>
                        {i.name}
                      </Link>
                    </MenuItem>
                  );
                }
                return (
                  <MenuItem key={i._id}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={i.url || ''}
                      key={i._id}
                    >
                      {i.name}
                    </a>
                  </MenuItem>
                );
              })}
            </Menu>
          </MenuWrapper>
          <Burger>
            <Button
              icon="burger"
              basic
              onClick={() => {
                setMenuVisible(true);
              }}
            />
          </Burger>
          <ResponsiveMenu
            position="left"
            sections={menuItems}
            visible={menuVisible}
            onClose={() => setMenuVisible(false)}
            renderLink={renderLink}
          />
        </Wrapper>
      </MaxWidthBox>
    </FullWidthBox>
  );
};

export default withRouter(Header) as any;
