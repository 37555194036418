import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import PagesApi from '../../api/PagesApi';
import config from '../../config';
import { ExtraArgumentType, RootState } from '../../configureStore';
import AppSettings from '../../library/AppSettings';
import NotFoundError from '../../NotFoundError';

const create = actionCreatorFactory('DOMAIN_RESOLVER');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadAppSettings = createAsync<void, AppSettings>(
  'GET_SETTINGS',
  async () => {
    try {
      const settings = await PagesApi.publicGetSettings(config.PROJECT_NAME);
      return {
        CSMAppSpace: config.PROJECT_NAME,
        ...settings,
      } as AppSettings;
    } catch (e: any) {
      throw new NotFoundError(e);
    }
  },
);

export const setAppSettings = create<{ settings: AppSettings }>('SET_SETTINGS');
