import { mb, mediaSizes } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { css } from '../../theme/styled-components';
import fluidTypography from '../../utilities/fluidTypography';
const Wrapper = styled.div`
  margin: ${rem(8)};
  box-shadow: 0 ${rem(2)} 0 rgba(0, 0, 0, 0.04);
  border-radius: ${rem(12)};
`;

const ImageWrapper = styled.div<{ backgroundImageUrl?: string }>`
  border-radius: ${rem(12)} ${rem(12)} 0 0;
  width: auto;
  height: ${rem(180)};
  ${mb('l')} {
    height: ${rem(270)};
  }
  transition: all 0.3s linear;
  ${({ backgroundImageUrl }) => {
    if (backgroundImageUrl) {
      return css`
        background-image: url('${backgroundImageUrl}');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center center;
        :hover {
          background-size: 110%;
        }
      `;
    }
    return css`
      background: #f7f8fc;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        width: ${rem(120)};
        height: auto;
        transition: all 0.3s linear;
      }
      :hover {
        img {
          transform: scale(1.2);
        }
      }
    `;
  }}
`;

const EventInfo = styled.div`
  border-radius: 0 0 ${rem(12)} ${rem(12)};
  background: #fff;
  padding: ${rem(16)};
  ${mb('l')} {
    padding: ${rem(24)};
  }
`;

const Title = styled.div`
  ${fluidTypography(14, 20, mediaSizes.l, 1920)};
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  :hover {
    text-decoration: underline;
  }
  padding-bottom: ${rem(8)};
  ${mb('l')} {
    padding-bottom: ${rem(16)};
  }
`;

const Subtitle = styled.div`
  color: #707070;
  > * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    ${fluidTypography(12, 16, mediaSizes.l, 1920)};
  }
`;

type OwnProps = {
  title: string;
  sportGroundName: string;
  date: string;
  imgSrc?: string;
};

const EventPreview: React.FC<OwnProps> = ({
  title,
  date,
  sportGroundName,
  imgSrc,
}) => {
  return (
    <Wrapper>
      <ImageWrapper backgroundImageUrl={imgSrc}>
        {!imgSrc && <img src={'/logo.png'} alt={title} />}
      </ImageWrapper>
      <EventInfo>
        <Title>{title}</Title>
        <Subtitle>
          <div>{sportGroundName}</div>
          <div>{date}</div>
        </Subtitle>
      </EventInfo>
    </Wrapper>
  );
};

export default EventPreview;
