import React from 'react';

const ChildIcon = (size: number = 24, color: string = '#000') => {
  return (
    <svg
      version="1.1"
      id="child"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      width={size}
      height={size}
    >
      <path
        fill={color}
        d="M14.5,9.2c0.7,0,1.2,0.6,1.2,1.2s-0.6,1.2-1.2,1.2s-1.2-0.6-1.2-1.2S13.8,9.2,14.5,9.2z M9.5,9.2c0.7,0,1.2,0.6,1.2,1.2
	s-0.6,1.2-1.2,1.2s-1.2-0.6-1.2-1.2S8.8,9.2,9.5,9.2z M16.1,14H7.9c-0.1,0-0.3,0.1-0.3,0.3c0,0,0,0.1,0,0.1c1.2,2.4,4.2,3.3,6.6,2.1
	c0.9-0.5,1.6-1.2,2.1-2.1C16.4,14.2,16.3,14.1,16.1,14C16.2,14,16.1,14,16.1,14z M22.9,11.3c-0.3-1.5-1.3-2.7-2.8-3.2
	c-0.5-1.1-1.3-2.1-2.2-2.9C14.2,2,8.5,2.3,5.3,6.1c-0.6,0.6-1,1.3-1.4,2.1c-1.5,0.4-2.6,1.7-2.8,3.2c-0.1,0.4-0.1,0.9,0,1.3
	c0.3,1.5,1.3,2.7,2.8,3.2c0.5,1.1,1.3,2.1,2.2,2.9c3.4,3,8.5,3,11.9,0c0.9-0.8,1.6-1.8,2.2-2.9c1.5-0.4,2.5-1.7,2.8-3.2
	C23,12.2,23,11.8,22.9,11.3z M19,14c-0.1,0-0.2,0-0.3,0c-0.2,0.7-0.5,1.3-0.9,1.9c-2.1,3.2-6.4,4.1-9.7,2c-0.8-0.5-1.5-1.2-2-2
	c-0.4-0.6-0.7-1.2-0.9-1.9c-0.1,0-0.2,0-0.3,0c-1.1,0-2-0.9-2-2s0.9-2,2-2c0.1,0,0.2,0,0.3,0c0.2-0.7,0.5-1.3,0.9-1.9
	c2.1-3.2,6.4-4.1,9.7-2c0.8,0.5,1.5,1.2,2,2c0.4,0.6,0.7,1.2,0.9,1.9c0.1,0,0.2,0,0.3,0c1.1,0,2,0.9,2,2S20.1,14,19,14z"
      />
    </svg>
  );
};

export default ChildIcon;
