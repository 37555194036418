import { NormalizedEntities } from '../library/App';
import { EntitiesState } from '../pages/App/reducer';

export default function<
  T extends keyof EntitiesState,
  E extends EntitiesState[T]['']
>(type: T, arrayOfEntities: E[], id: string = '_id'): NormalizedEntities<T> {
  return {
    entities: {
      [type]: arrayOfEntities.reduce(
        (acc, item: any) => {
          acc[item[id]] = item;
          return acc;
        },
        {} as any,
      ),
    },
    results: arrayOfEntities.map((item: any) => item[id]),
    original: arrayOfEntities,
  };
}
