import __ from '../../utilities/__';

export const required = (value: string) =>
  value ? null : __('Pole je povinné');

export const email = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(value)
    ? __('Chybný formát emailu')
    : null;

export const phone = (value: string) =>
  value && !/^[0-9 +/]+$/.test(value)
    ? __('Telefónne čislo môže obsahovať len čísla, /, + alebo medzeru')
    : null;

export const onlyNumbers = (value: string) =>
  value && !/^[0-9]+$/.test(value)
    ? __('Rodné číslo môže obsahovať len čísla.')
    : null;

export const maxLength = (max: number) => (value: string) =>
  value && value.length > max
    ? __(`Pole nesmie obsahovať viac ako ${max} znakov`)
    : null;

export const minLength = (min: number) => (value: string) =>
  value && value.length < min
    ? __(`Pole musí obsahovať aspoň ${min} znakov`)
    : null;

export const length = (max: number) => (value: string) =>
  value && value.length !== max
    ? __(`Pole musí obsahovať presne ${max} znakov`)
    : null;

export const length10 = length(10);
export const maxLength25 = maxLength(25);
export const maxLength8 = maxLength(8);
export const minLength6 = minLength(6);
