import { commit } from '@sportnet/redux-list/ducks';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { ExtraArgumentType, RootState } from '../../configureStore';
import ForbiddenError from '../../ForbiddenError';
import InternalServerError from '../../InternalServerError';
import { NormalizedEntities } from '../../library/App';
import NotFoundError from '../../NotFoundError';
import UnauthorizedError from '../../UnauthorizedError';
import normalizeEntities from '../../utilities/normalizeEntities';
import __ from '../../utilities/__';
import { initializeOrSetListParams, updateEntities } from '../App/actions';
const create = actionCreatorFactory('ARTICLE');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadOrganization = createAsync<
  { id: string },
  NormalizedEntities<'organizations'>
>('LOAD_ORGANIZATION', async ({ id }, dispatch, getState, { CoreApi }) => {
  try {
    const response = await CoreApi.organizationPPOProfile(id);
    return normalizeEntities('organizations', [response]);
  } catch (e: any) {
    if (e && e.details && e.details.code === 401) {
      throw new UnauthorizedError(e);
    }
    if (e && e.details && e.details.code === 403) {
      throw new ForbiddenError(e);
    }
    if (e && e.details && e.details.code === 404) {
      throw new NotFoundError(e);
    }
    throw new InternalServerError(e);
  }
});

const normalizeOrganizations = (data: any[]) => {
  return {
    entities: {
      organizations: data.reduce((acc, item) => {
        return { ...acc, [String(item._id)]: item };
      }, {}) as { [key: string]: any },
    },
    result: data.map((item) => item._id),
  };
};

export const loadOrganizations = createAsync<
  { seasonId: string },
  NormalizedEntities<'organizations'>
>('LOAD_ORGANIZATIONS', async ({ seasonId }, dispatch, getState, { Api }) => {
  try {
    const { items } = await Api.getPublicOrgranizations(config.PROJECT_NAME, {
      seasonId,
    });
    dispatch(
      initializeOrSetListParams.action({
        listName: config.ORGANIZATIONS_LIST_NAME,
        params: {
          seasonId,
        },
      }),
    );
    await dispatch(
      commit.action({
        listName: config.ORGANIZATIONS_LIST_NAME,
        load: async () => {
          try {
            const { result, entities } = normalizeOrganizations(items);

            updateEntities(entities);
            return {
              results: result,
              total: 0,
            };
          } catch (e: any) {
            alert(__('Nepodarilo sa získať zoznam škôl'));
            return {
              results: [],
              total: 0,
            };
          }
        },
      }),
    );
    return normalizeEntities('organizations', items);
  } catch (e: any) {
    if (e && e.details && e.details.code === 401) {
      throw new UnauthorizedError(e);
    }
    if (e && e.details && e.details.code === 403) {
      throw new ForbiddenError(e);
    }
    throw new InternalServerError(e);
  }
});
