import { ACCESS_TOKEN_COOKIE_NAME } from '@sportnet/ui/Authorization';
import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import defaultWidgets from '@sportnet/content/editor/defaultWidgets';
import Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import Api from '../../api/Api';
import config from '../../config';
import { ReduxConnectProps, RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import required from '../../utilities/required';
import __ from '../../utilities/__';
import { ITeam } from '../AdminTeams/types';
import { ISeason } from './types';

const availableWidgets = defaultWidgets.filter((i: any) =>
  [
    'text',
    'table',
    'document',
    'hr',
    'photo',
    'video',
    'photogallery',
  ].includes(i.type),
);

type SportGround = {
  _id: string;
  name: string;
  geo: { type: string; coordinates: number[] };
};

const mapStateToProps = (state: RootState, props: { form: string }) => {
  const selector = formValueSelector(props.form);
  return {
    widgets: selector(state, 'description.widgets'),
    teamId: selector(state, 'teamId'),
  };
};

type OwnProps = {
  update?: boolean;
};

type Props = RouteProps<{ appSpace: string; id: string }> &
  ReduxConnectProps & { form: string } & ReturnType<typeof mapStateToProps>;

const AdminEventForm: React.FC<OwnProps & Props> = ({
  form,
  update,
  params: { appSpace, id },
  dispatch,
  widgets,
  teamId,
}) => {
  const [sportGrounds, setSportGrounds] = React.useState<SportGround[]>([]);
  const [isFetchingSportGrounds, setIsFetchingSportGrounds] =
    React.useState(false);
  // const [athletes, setAthletes] = React.useState<any[]>([]);
  // const [isFetchingAthletes, setIsFetchingAthletes] = React.useState(false);

  const [teams, setTeams] = React.useState<ITeam[]>([]);
  const [isFetchingTeams, setIsFetchingTeams] = React.useState(false);

  const [nomination, setNomination] = React.useState<
    Array<{ _id: string; name: string }>
  >([]);
  const [isFetchingNomination, setIsFetchingNomination] = React.useState(false);
  const [isRemovingPlayer, setIsRemovingPlayer] = React.useState('');

  const [seasons, setSeasons] = React.useState<ISeason[]>([]);
  const [isFetchingSeasons, setIsFetchingSeasons] = React.useState(false);

  const [Editor, setEditor] = React.useState<any>(null);

  React.useEffect(() => {
    if (document) {
      const editor = require('@sportnet/content/editor');
      setEditor(editor.default);
    }
  }, []);

  const getSeasons = React.useCallback(async () => {
    try {
      setIsFetchingSeasons(true);
      const { items } = await Api.getAppSpaceSeasons(
        appSpace,
        config.PROJECT_NAME,
      );
      setSeasons(items);
    } catch (e: any) {
      console.error(e);
      alert(__('Nepodarilo sa získať zoznam ročníkov'));
    } finally {
      setIsFetchingSeasons(false);
    }
  }, [appSpace]);

  const getNominations = React.useCallback(async () => {
    if (id !== 'new' && teamId) {
      setIsFetchingNomination(true);
      try {
        const res = await Api.adminGetEventNomination(
          appSpace,
          config.PROJECT_NAME,
          id,
          teamId,
        );
        setNomination(
          res.athletes.map((a) => ({
            _id: a.sportnetUser._id,
            name: a.sportnetUser.name,
          })),
        );
      } catch (e: any) {
        alert(__('Nepodarilo sa získať podrobnosti aktivity'));
      } finally {
        setIsFetchingNomination(false);
      }
    }
  }, [appSpace, id, teamId]);

  const getSportGrounds = React.useCallback(async () => {
    setIsFetchingSportGrounds(true);
    try {
      const res = await Api.adminGetSportGrounds(appSpace, config.PROJECT_NAME);
      setSportGrounds(res.sportGrounds || []);
    } catch (e: any) {
      alert(__('Nepodarilo sa získať zoznam miest konania.'));
    } finally {
      setIsFetchingSportGrounds(false);
    }
  }, [appSpace]);

  const getTeams = React.useCallback(async () => {
    setIsFetchingTeams(true);
    try {
      const res = await Api.adminGetTeams(appSpace, config.PROJECT_NAME, {
        active: true,
      });
      setTeams(res.teams);
    } catch (e: any) {
      alert(__('Nepodarilo sa získať zoznam tried.'));
    } finally {
      setIsFetchingTeams(false);
    }
  }, [appSpace]);

  React.useEffect(() => {
    getSeasons();
    getNominations();
    getSportGrounds();
    getTeams();
  }, [getSeasons, getNominations, getSportGrounds, getTeams]);

  const removePlayerFromNomination = async (sportnetId: string) => {
    try {
      setIsRemovingPlayer(sportnetId);
      const modifiedNomination = nomination.filter((n) => n._id !== sportnetId);
      const res = await Api.adminUpdateEventNomination(
        appSpace,
        config.PROJECT_NAME,
        id,
        teamId,
        {},
        { athletes: modifiedNomination.map((n) => ({ sportnetUser: n })) },
      );
      setNomination(
        res.athletes.map((a) => ({
          _id: a.sportnetUser._id,
          name: a.sportnetUser.name,
        })),
      );
    } catch (e: any) {
      alert(__('Účasť žiaka sa nepodarilo odstrániť.'));
    } finally {
      setIsRemovingPlayer('');
    }
  };

  // const fetchAthletes = React.useMemo(() => {
  //   setAthletes([]);
  //   return debounce(async (value: string) => {
  //     if (value.length > 3) {
  //       setIsFetchingAthletes(true);
  //       try {
  //         const res = await CoreApi.organizationPPOUsers(appSpace, {
  //           q: value,
  //         });
  //         setAthletes(res.users || []);
  //       } catch (e: any) {
  //         alert(__('Nepodarilo sa získať zoznam športovcov'));
  //       } finally {
  //         setIsFetchingAthletes(false);
  //       }
  //     }
  //   }, 300);
  // }, [appSpace]);

  // const renderCustomLabel = (i: { value: string }) => {
  //   const athlete = athletes.find((a: any) => a._id === i.value) as any;
  //   if (athlete) {
  //     return (
  //       <div>
  //         <div>{formatUserName(athlete)}</div>
  //         {athlete.birthdate && (
  //           <div style={{ fontSize: rem(10) }}>
  //             {__('nar.')} {format(new Date(athlete.birthdate), 'DD.MM.YYYY')}
  //           </div>
  //         )}
  //       </div>
  //     );
  //   }
  //   return i;
  // };

  // const nominatedIds = nomination.map(n => n._id);

  return (
    <>
      <Segment
        raised
        header={
          <SegmentHeader size="s" withSeparator>
            {__('Základné informácie')}
          </SegmentHeader>
        }
      >
        <Row>
          <Col xs={12} s={6}>
            <Field
              label={__('Názov aktivity')}
              name="name"
              component={FormField}
              required
              validate={[required]}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              label={__('Školský rok')}
              name="seasonId"
              disabled={update}
              component={FormField}
              type="theselectsimple"
              loading={isFetchingSeasons}
              options={seasons.map((i) => ({ label: i.name, value: i._id }))}
              required
              validate={[required]}
            />
          </Col>
          <Col xs={12}>
            <Field
              label={__('Dátum konania')}
              name="startDate"
              component={FormField}
              type="date"
              required
              validate={[required]}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              label={__('Začiatok aktivity')}
              name="startDate"
              component={FormField}
              type="time"
              required
              validate={[required]}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              label={__('Koniec aktivity')}
              name="endDate"
              component={FormField}
              type="time"
              required
              validate={[required]}
            />
          </Col>
          <Col xs={12}>
            <Field
              label={__('Miesto konania')}
              name="sportGround"
              component={FormField}
              type="theselectsimple"
              loading={isFetchingSportGrounds}
              required
              validate={[required]}
              options={sportGrounds.map((i) => ({
                label: i.name,
                value: i._id,
              }))}
              format={(i: any) => {
                if (i && i._id) {
                  return i._id;
                }
                return i;
              }}
            />
          </Col>
          <Col xs={12}>
            <Field
              label={__('Trieda')}
              name="teamId"
              disabled={id !== 'new'}
              component={FormField}
              type="theselectsimple"
              loading={isFetchingTeams}
              required
              validate={[required]}
              options={teams.map((i) => ({ label: i.name, value: i._id }))}
              format={(i: any) => {
                if (i && i._id) {
                  return i._id;
                }
                return i;
              }}
            />
          </Col>
        </Row>
      </Segment>
      <Segment
        raised
        header={
          <SegmentHeader size="s" withSeparator>
            {__('Publikácia')}
          </SegmentHeader>
        }
      >
        <Field
          label={__('Publikované')}
          name="publication"
          component={FormField}
          type="checkbox"
        />
      </Segment>
      <Segment
        raised
        header={
          <SegmentHeader size="s" withSeparator>
            {__('Popis')}
          </SegmentHeader>
        }
      >
        {!!Editor && (
          <Editor
            onChange={(e: any) =>
              dispatch(change(form, 'description.widgets', e))
            }
            availableWidgets={availableWidgets}
            initialValue={widgets || []}
            contentDivider="default"
            appId={config.APP_ID}
            appSpace={appSpace}
            token={Cookies.get(ACCESS_TOKEN_COOKIE_NAME) || ''}
          />
        )}
      </Segment>
      {id !== 'new' && (
        <Segment
          raised
          loading={isFetchingNomination}
          header={<SegmentHeader size="s">{__('Účastníci')}</SegmentHeader>}
        >
          {/* <Field
            component={FormField}
            name="sportnetId"
            type="theselect"
            options={athletes
              .filter(a => !nominatedIds.includes(a._id))
              .map((athlete: any) => ({
                label: formatUserName(athlete),
                value: athlete._id,
              }))}
            loading={isFetchingAthletes}
            onChangeInput={(i: string) => {
              fetchAthletes(i);
            }}
            onChange={(e: any) => {
              e.preventDefault();
              onNominationChange([
                { sportnetUser: { _id: e.value, name: e.label } },
              ]);
            }}
            format={(i: string | { _id: string; name: string }) => {
              if (typeof i === 'string') {
                const option = athletes.find(
                  (a: any) => a && String(a._id) === i,
                ) as any;
                if (option) {
                  return {
                    value: option._id,
                    label: formatUserName(option),
                  };
                }
              } else if (i) {
                return i;
              }
              return '';
            }}
            label={__('Pridať žiaka')}
            renderLabel={renderCustomLabel}
            placeholder={__('Minimálne 4 znaky...')}
          /> */}
          <BasicTable
            rows={nomination}
            renderRow={(i: any) => [
              i.name,
              <Button
                key={i._id}
                loading={isRemovingPlayer === i._id}
                onClick={() => removePlayerFromNomination(i._id)}
                danger
              >
                {__('Odstrániť')}
              </Button>,
            ]}
            columns={[
              { header: __('Meno a priezvisko') },
              { width: 100, header: '' },
            ]}
          />
        </Segment>
      )}
    </>
  );
};

export default compose(
  reduxForm<{}, OwnProps>({ enableReinitialize: true }),
  withRouter,
  connect(mapStateToProps),
)(AdminEventForm);
