import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import useQuery, { NumberParam } from '@sportnet/query-hoc/useQuery';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { commit, getListTotal, isCommiting } from '@sportnet/redux-list/ducks';
import Api from '../../api/Api';
import Paginator from '../../components/Paginator';
import config from '../../config';
import { ReduxConnectProps, RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import __ from '../../utilities/__';
import { initializeOrSetListParams, updateEntities } from '../App/actions';
import { listTeamsSelector } from './selectors';

const mapStateToProps = (state: RootState) => ({
  teams: listTeamsSelector(state),
  total: getListTotal(config.ADMIN_TEAMS_LIST_NAME)(state),
  isFetching: isCommiting(config.ADMIN_TEAMS_LIST_NAME)(state),
});
const mapDispatchToProps = {
  setList: initializeOrSetListParams.action,
  commitList: commit.action,
  updateTeams: updateEntities,
};

type Props = RouteProps<{ appSpace: string }> &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReduxConnectProps;

const AdminTeamsList: React.FC<Props> = ({
  setList,
  commitList,
  router,
  params: { appSpace },
  location: { search, pathname },
  updateTeams,
  teams,
  isFetching,
  total,
}) => {
  const [page, setPage] = React.useState(1);
  const normalizeTeams = (data: any[]) => {
    return {
      entities: {
        teams: data.reduce((acc, item) => {
          return { ...acc, [String(item._id)]: item };
        }, {}) as { [key: string]: any },
      },
      result: data.map((item) => item._id),
    };
  };
  const { query, setQuery: setParameter } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    {
      parameters: {
        offset: NumberParam(0),
      },
    },
  );

  const onChangePage = (newPage: number) => {
    const offset = (newPage - 1) * config.ADMIN_TEAMS_LIST_LIMIT;
    setPage(newPage);
    setParameter({ offset });
  };

  React.useEffect(() => {
    setList({
      listName: config.ADMIN_TEAMS_LIST_NAME,
      params: {
        ...query,
      },
    });
    commitList({
      listName: config.ADMIN_TEAMS_LIST_NAME,
      load: async () => {
        try {
          const res = await Api.adminGetTeams(appSpace, config.PROJECT_NAME, {
            offset: query.offset,
            limit: config.ADMIN_TEAMS_LIST_LIMIT,
          });
          const { result, entities } = normalizeTeams(res.teams);

          updateTeams(entities);
          return {
            results: result,
            total: res.total,
          };
        } catch (e: any) {
          alert(__('Nepodarilo sa získať zoznam športových udalostí'));
          return {
            results: [],
            total: 0,
          };
        }
      },
    });
  }, [appSpace, query.offset]);

  return (
    <ScrollLayout
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              onClick={() =>
                router.push(`/admin/appSpace/${appSpace}/teams/new`)
              }
              primary
            >
              {__('Vytvoriť novú triedu')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment
        loading={!!isFetching}
        header={
          <SegmentHeader withSeparator size="s">
            {__('Triedy')}
          </SegmentHeader>
        }
      >
        <Segment raised>
          <BasicTable
            rows={teams}
            rowKey="_id"
            columns={[{ header: __('Názov') }]}
            renderRow={(i) => [i.name]}
            onClickRow={(i) =>
              router.push(`/admin/appSpace/${appSpace}/teams/${i._id}`)
            }
          />
          <Paginator
            limit={config.ADMIN_TEAMS_LIST_LIMIT}
            page={page}
            onChangePage={onChangePage}
            total={total || 0}
            loading={!!isFetching}
          />
        </Segment>
      </Segment>
    </ScrollLayout>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AdminTeamsList,
);
