import React from 'react';

const InstagramIcon = (size: number = 24) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      width={size}
      height={size}
    >
      <path
        fill="#E4405F"
        d="M12,5.8c-3.4,0-6.2,2.8-6.2,6.2s2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2S15.4,5.8,12,5.8z M12,16c-2.2,0-4-1.8-4-4
	s1.8-4,4-4s4,1.8,4,4S14.2,16,12,16z M23.9,7.1c0-1-0.2-2-0.6-2.9C23.1,3.3,22.6,2.6,22,2c-0.6-0.6-1.3-1.1-2.1-1.4
	c-0.9-0.3-1.9-0.5-2.9-0.6C15.7,0,15.3,0,12,0S8.3,0,7.1,0.1c-1,0-2,0.2-2.9,0.6C3.3,0.9,2.6,1.4,2,2C1.4,2.6,0.9,3.3,0.6,4.1
	C0.3,5.1,0.1,6.1,0.1,7.1C0,8.3,0,8.7,0,12s0,3.7,0.1,4.9c0,1,0.2,2,0.6,2.9C0.9,20.7,1.4,21.4,2,22c0.6,0.6,1.3,1.1,2.1,1.4
	c0.9,0.3,1.9,0.5,2.9,0.6C8.3,24,8.7,24,12,24s3.7,0,4.9-0.1c1,0,2-0.2,2.9-0.6c1.6-0.6,2.9-1.9,3.5-3.5c0.3-0.9,0.5-1.9,0.6-2.9
	C24,15.7,24,15.3,24,12S24,8.3,23.9,7.1z M21.8,16.8c0,0.8-0.2,1.5-0.4,2.2c-0.2,0.5-0.5,1-0.9,1.4c-0.4,0.4-0.9,0.7-1.4,0.9
	c-0.7,0.3-1.5,0.4-2.2,0.4c-1.3,0.1-1.6,0.1-4.9,0.1s-3.6,0-4.9-0.1c-0.8,0-1.5-0.2-2.2-0.4c-0.5-0.2-1-0.5-1.4-0.9
	c-0.4-0.4-0.7-0.9-0.9-1.4c-0.3-0.7-0.4-1.5-0.4-2.2c0-1.3-0.1-1.6-0.1-4.8c0-3.2,0-3.6,0.1-4.9c0-0.8,0.2-1.5,0.4-2.2
	c0.2-0.5,0.5-1,0.9-1.4c0.4-0.4,0.9-0.7,1.4-0.9c0.7-0.3,1.5-0.4,2.2-0.4c1.3,0,1.7-0.1,4.9-0.1l0,0c3.2,0,3.6,0,4.8,0.1
	c0.8,0,1.5,0.1,2.2,0.4c1,0.4,1.9,1.2,2.3,2.3c0.3,0.7,0.4,1.5,0.4,2.2c0.1,1.3,0.1,1.6,0.1,4.8S21.8,15.6,21.8,16.8z M19.8,5.6
	c0,0.8-0.6,1.4-1.4,1.4S17,6.4,17,5.6s0.6-1.4,1.4-1.4c0,0,0,0,0,0C19.2,4.2,19.8,4.8,19.8,5.6z"
      />
    </svg>
  );
};

export default InstagramIcon;
