import Authorization from '@sportnet/ui/Authorization';
import Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Api from '../../api/Api';
import CmsApi from '../../api/CmsApi';
import CoreApi from '../../api/CoreApi';
import config from '../../config';
import { RootState } from '../../configureStore';
import { IAuthorizationResponse } from '../../library/App';
import {
  Appspace,
  setActiveAppspace,
  setAppspaces,
  setAuthUser,
} from '../../pages/App/actions';
import { activeAppspaceSelector } from '../../pages/App/selectors';
import AdminLayout from '../AdminLayout';
import Page from '../Page';

interface OwnProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
  children?: React.ReactNode;
}

const mapStateToProps = (state: RootState) => ({
  activeAppSpace: activeAppspaceSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouteComponentProps<
  {
    appSpace: string;
  },
  {}
> &
  OwnProps &
  IMapStateToProps;

class Routes extends React.PureComponent<Props> {
  componentDidMount() {
    const {
      params: { appSpace },
    } = this.props;
    const hash: any = window.location.hash
      .substr(1)
      .split('&')
      .reduce((acc, next) => {
        const hashItem = next.split('=');
        return { ...acc, [hashItem[0]]: decodeURIComponent(hashItem[1]) };
      }, {});
    if (hash && hash.state && hash.state === 'ziadost') {
      Cookies.set('redirectToRequest', '1');
    }
    if (appSpace) {
      this.props.dispatch(setActiveAppspace(appSpace));
    }
  }
  componentWillReceiveProps(nextProps: Props) {
    const {
      params: { appSpace },
    } = nextProps;
    if (this.props.params.appSpace !== appSpace) {
      this.props.dispatch(setActiveAppspace(appSpace));
    }
  }
  onAuthorized = (data: IAuthorizationResponse) => {
    Api.setToken(data.accessToken);
    CoreApi.setToken(data.accessToken);
    CmsApi.setToken(data.accessToken);
    this.props.dispatch(setAuthUser(data));
    const redirectToRequest = Cookies.get('redirectToRequest');

    if (redirectToRequest) {
      Cookies.remove('redirectToRequest');
      this.props.router.push('/ziadost');
    }
  };
  onAppspaceSelect = async (data: Appspace) => {
    this.props.dispatch(setActiveAppspace(data.org_profile._id));
    this.props.router.push(`/admin/appSpace/${data.org_profile._id}/events`);
  };
  // onAppInfoReceived = (data: ApplicationInfo) => {
  //   this.props.dispatch(setApplicationInfo(data));
  // };
  onAppspacesReceived = (data: Appspace[]) => {
    this.props.dispatch(setAppspaces(data));
  };
  render() {
    const { children } = this.props;
    return (
      // <EntryAnimation key={'admin'}>
      <Page>
        <Authorization
          appId={config.APP_ID}
          appspace={this.props.activeAppSpace}
          onAppspaceSelect={this.onAppspaceSelect}
          onAuthorized={this.onAuthorized}
          onAppspacesReceived={this.onAppspacesReceived}
          scope="profile,messages"
          basePath="/admin"
        >
          <AdminLayout>{children}</AdminLayout>
        </Authorization>
      </Page>
      // </EntryAnimation>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Routes));
