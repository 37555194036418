import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory } from 'react-router';
import configureStore from './configureStore';
import Analytics from './containers/Analytics';
import ConsentPanel from './containers/Analytics/ConsentPanel';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import GlobalStyles from './theme/globalStyles';
import { ThemeProvider } from './theme/styled-components';
import { theme } from './theme/theme';

let state = {};
if (typeof window !== 'undefined') {
  state = (window as any).__REDUX_STATE__;
}
if (!document || !window.document) {
  (document as any) = {};
  (window.document as any) = {};
}

const store = configureStore(state !== '{{SSR_INITIAL_STATE}}' ? state : {});

ReactDOM.hydrate(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Routes history={browserHistory} />
      <Analytics />
      <ConsentPanel />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);

serviceWorker.unregister();
