import BasicTable from '@sportnet/ui/BasicTable';
import Col, { Row } from '@sportnet/ui/Grid';
import Input from '@sportnet/ui/Input';
import Segment from '@sportnet/ui/Segment';
import TheSelectSimple from '@sportnet/ui/TheSelectSimple';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { isCommiting } from '@sportnet/redux-list/ducks';
import { removeAccents } from 'sportnet-utilities';
import styled from 'styled-components';
import Api from '../../api/Api';
import PublicPage from '../../components/PublicPage';
import config from '../../config';
import { RootState } from '../../configureStore';
import __ from '../../utilities/__';
import { loadOrganizations } from './actions';
import { listOrganizationsSelector } from './selectors';

const schoolTypes = {
  'materska-skola': __('Materská škola'),
  'zakladna-skola': __('Základná škola'),
};

export const translateSchoolType = (
  type: 'materska-skola' | 'zakladna-skola',
) => schoolTypes[type];

export const Stat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.primary};
`;

export const StatCount = styled.div`
  font-weight: bold;
  font-size: ${rem(40)};
`;

export const StatDesc = styled.div`
  font-weight: 200;
`;

const mapStateToProps = (state: RootState) => ({
  items: listOrganizationsSelector(state),
  isFetchingItems: isCommiting(config.ORGANIZATIONS_LIST_NAME)(state),
});

const mapDispatchToProps = {
  loadItems: loadOrganizations.action,
};

const SchoolsList: React.FC<
  ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps &
    RouteComponentProps<{}, {}>
> = ({ router: { push }, location, loadItems, items, isFetchingItems }) => {
  const [isFetchingSeasons, setIsFetchingSeasons] = React.useState(false);
  const [seasons, setSeasons] = React.useState<
    Array<{ _id: string; name: string }>
  >([]);

  const [q, setQ] = React.useState('');
  const [type, setType] = React.useState<null | string>(null);
  const [season, setSeason] = React.useState<null | string>(null);

  const [eventsCount, setEventsCount] = React.useState<{
    [key: string]: number;
  }>({});
  const [eventsCountIsFetching, setEventsCountIsFetching] =
    React.useState(false);

  const [athletesCount, setAthletesCount] = React.useState<{
    [key: string]: number;
  }>({});
  const [athletesCountIsFetching, setAthletesCountIsFetching] =
    React.useState(false);

  const getSeasons = React.useCallback(async () => {
    try {
      setIsFetchingSeasons(true);
      const { items: seasonItems } = await Api.getPublicSeasons(
        config.PROJECT_NAME,
      );
      setSeasons(seasonItems);
      if (!season) {
        await loadItems({ seasonId: seasonItems[0]._id });
        setSeason(seasonItems[0]._id);
      } else {
        await loadItems({ seasonId: season });
      }
    } catch (e: any) {
      alert(__('Nepodarilo sa získať zoznam ročníkov.'));
    } finally {
      setIsFetchingSeasons(false);
    }
  }, []);

  React.useEffect(() => {
    getSeasons();
  }, [getSeasons]);

  React.useEffect(() => {
    if (season) {
      loadItems({ seasonId: season });
    }
  }, [season]);

  const getEventsCount = React.useCallback(async () => {
    if (season) {
      try {
        setEventsCountIsFetching(true);
        const { byAppSpace } = await Api.getEventsCountBySeasonId(
          config.PROJECT_NAME,
          season,
        );
        setEventsCount(byAppSpace);
      } catch (e: any) {
        // alert(__('Nepodarilo sa získať zoznam zapojených škôl'));
      } finally {
        setEventsCountIsFetching(false);
      }
    }
  }, [season]);

  const getTeamsAthletesCount = React.useCallback(async () => {
    if (season) {
      try {
        setAthletesCountIsFetching(true);
        const { items: athletesItems } = await Api.getTeamsAthletesCount(
          config.PROJECT_NAME,
          season,
        );
        setAthletesCount(
          athletesItems.reduce(
            (acc: { [key: string]: number }, i) => ({
              ...acc,
              [i.appSpace]: (acc[i.appSpace] || 0) + i.athletesCount,
            }),
            {},
          ),
        );
      } catch (e: any) {
        //
      } finally {
        setAthletesCountIsFetching(false);
      }
    }
  }, [season]);

  React.useEffect(() => {
    getEventsCount();
    getTeamsAthletesCount();
  }, [getEventsCount, getTeamsAthletesCount]);

  const filteredItems = items
    .filter((i) => removeAccents(i.name).match(RegExp(removeAccents(q), 'i')))
    .filter((i) =>
      type ? i.type === type : Object.keys(schoolTypes).includes(i.type),
    );

  const filteredAppSpaces = filteredItems.map((i) => i._id);

  let filteredEventsCount = 0;
  Object.keys(eventsCount).forEach((k) => {
    if (filteredAppSpaces.includes(k)) {
      filteredEventsCount += eventsCount[k];
    }
  });

  let filteredAthletesCount = 0;
  Object.keys(athletesCount).forEach((k) => {
    if (filteredAppSpaces.includes(k)) {
      filteredAthletesCount += athletesCount[k];
    }
  });

  return (
    <PublicPage title={__('Zoznam zapojených škôl')}>
      <Segment raised>
        <Row>
          <Col xs={12} m={5}>
            <Input
              value={q}
              onChange={(e) => setQ(e.target.value)}
              placeholder={__('Názov školy...')}
            >
              <Input.Icon name="search" />
              <input />
            </Input>
          </Col>
          <Col xs={12} s={6} m={4}>
            {!!window.matchMedia && (
              <TheSelectSimple
                value={type}
                clearable={false}
                options={[
                  { label: __('Materské a základné školy'), value: null },
                  { label: __('Materské školy'), value: 'materska-skola' },
                  { label: __('Základné školy'), value: 'zakladna-skola' },
                ]}
                onChange={(e: string | null) => setType(e)}
              />
            )}
          </Col>
          <Col xs={12} s={6} m={3}>
            {!!window.matchMedia && (
              <TheSelectSimple
                value={season || (seasons.length ? seasons[0]._id : null)}
                clearable={false}
                loading={isFetchingSeasons}
                options={seasons.map((s) => ({
                  label: s.name,
                  value: s._id,
                }))}
                onChange={(e: string | null) => setSeason(e)}
              />
            )}
          </Col>
        </Row>
      </Segment>
      <Row>
        <Col xs={12} m={4}>
          <Segment raised loading={!!isFetchingItems}>
            <Stat>
              <StatCount>{filteredItems.length}</StatCount>
              <StatDesc>{__('zapojených škôl')}</StatDesc>
            </Stat>
          </Segment>
        </Col>
        <Col xs={12} m={4}>
          <Segment raised loading={athletesCountIsFetching}>
            <Stat>
              <StatCount>{filteredAthletesCount}</StatCount>
              <StatDesc>{__('zapojených detí')}</StatDesc>
            </Stat>
          </Segment>
        </Col>
        <Col xs={12} m={4}>
          <Segment raised loading={eventsCountIsFetching}>
            <Stat>
              <StatCount>{filteredEventsCount}</StatCount>
              <StatDesc>{__('zrealizovaných aktivít')}</StatDesc>
            </Stat>
          </Segment>
        </Col>
      </Row>
      <Segment raised>
        <BasicTable
          rows={[...filteredItems]}
          onClickRow={(i) =>
            push(
              `/skola/${i._id}?eventsSeason=${season || ''}&teamsSeason=${
                season || ''
              }`,
            )
          }
          renderRow={(i) => [
            i.name,
            translateSchoolType(i.type as any),
            eventsCount[i._id] || 0,
            athletesCount[i._id] || 0,
          ]}
          rowKey="_id"
          columns={[
            { header: __('Názov školy') },
            { header: __('Druh') },
            { header: __('Počet aktivít') },
            { header: __('Počet detí') },
          ]}
        />
      </Segment>
    </PublicPage>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SchoolsList);
