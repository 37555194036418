import * as React from 'react';
import Loader from '../../components/Loader';
import styled from '../../theme/styled-components';

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

class Loading extends React.PureComponent<{}> {
  render() {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }
}

export default Loading;
