import __ from './__';

function mod97(i: string) {
  let checksum: string | number = i.slice(0, 2);
  let fragment;
  for (let offset = 2; offset < i.length; offset += 7) {
    fragment = String(checksum) + i.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

export default (input: string) => {
  const iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
  let digits;
  // check syntax and length
  if (!code || iban.length !== 24) {
    return __('IBAN musí obsahovať presne 24 znakov.');
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, letter => {
    return String(letter.charCodeAt(0) - 55);
  });
  // final check
  const checksum = mod97(digits);
  if (checksum !== 1) {
    return __('Zadali ste neplatný IBAN.');
  }
  return null;
};
