import React from 'react';

const FacebookIcon = (size: number = 24) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      width={size}
      height={size}
    >
      <path
        fill="#1877F2"
        d="M24,12c0,5.9-4.3,10.9-10.1,11.9v-8.4h2.8l0.5-3.5h-3.3V9.7c0-0.1,0-0.3,0-0.4c0.1-0.9,1-1.6,1.9-1.5h1.5V4.9
	c-0.9-0.1-1.8-0.2-2.7-0.2c-2.7,0-4.5,1.7-4.5,4.7V12h-3v3.5h3v8.4C4.3,22.9,0,17.9,0,12C0,5.4,5.4,0,12,0C18.6,0,24,5.4,24,12z"
      />
    </svg>
  );
};

export default FacebookIcon;
