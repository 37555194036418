import { lighten } from 'polished';
import * as React from 'react';

export default ({ color, size }: { color: string; size: string }) => {
  return (
    <svg name="male" width={size} height={size}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
        <path
          fill={lighten(0.1, color)}
          fillRule="evenodd"
          d="M15 0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0z"
        />
        <path
          fill={color}
          fillRule="evenodd"
          d="M15 30c-4.264 0-8.104-1.788-10.835-4.644a3.379 3.379 0 0 1 1.599-1.895l5.038-2.392a2.249 2.249 0 0 0 1.497-2.152v-1.555c0-.239-.136-.478-.272-.718-.817-.956-1.361-2.033-1.77-3.109a.65.65 0 0 0-.408-.598.862.862 0 0 1-.273-.598v-1.914c0-.119.137-.359.137-.478.272-.239.408-.598.408-.837V6.479c0-.838.272-1.555.681-2.153 2.587-1.794 6.399-1.794 9.122 0 .681.598.953 1.435.817 2.272V9.11c0 .359.136.598.408.837.136.119.273.359.273.478v1.914c0 .359-.273.598-.545.717-.272.12-.545.359-.681.718-.408.837-.817 1.674-1.498 2.512l-.408.358c-.136.24-.272.479-.272.718v1.674c0 .957.68 1.914 1.634 2.273l5.309 2.272a3.988 3.988 0 0 1 1.436 1.156C23.645 27.954 19.565 30 15 30z"
        />
      </svg>
    </svg>
  );
};
