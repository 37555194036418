import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import { format } from 'date-fns';
import useQuery, { NumberParam } from '@sportnet/query-hoc/useQuery';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { commit, getListTotal, isCommiting } from '@sportnet/redux-list/ducks';
import Api from '../../api/Api';
import Paginator from '../../components/Paginator';
import config from '../../config';
import { ReduxConnectProps, RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import __ from '../../utilities/__';
import { initializeOrSetListParams, updateEntities } from '../App/actions';
import { listEventsSelector } from './selectors';

const mapStateToProps = (state: RootState) => ({
  events: listEventsSelector(state),
  total: getListTotal(config.ADMIN_EVENTS_LIST_NAME)(state),
  isFetching: isCommiting(config.ADMIN_EVENTS_LIST_NAME)(state),
});
const mapDispatchToProps = {
  setList: initializeOrSetListParams.action,
  commitList: commit.action,
  updateEvents: updateEntities,
};

type Props = RouteProps<{ appSpace: string }> &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReduxConnectProps;

const AdminEventsList: React.FC<Props> = ({
  setList,
  commitList,
  router,
  params: { appSpace },
  location: { search, pathname },
  updateEvents,
  events,
  isFetching,
  total,
}) => {
  const [page, setPage] = React.useState(1);
  const normalizeEvents = (data: any[]) => {
    return {
      entities: {
        events: data.reduce((acc, item) => {
          return { ...acc, [String(item._id)]: item };
        }, {}) as { [key: string]: any },
      },
      result: data.map((item) => item._id),
    };
  };
  const { query, setQuery: setParameter } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    {
      parameters: {
        offset: NumberParam(0),
      },
    },
  );

  const onChangePage = (newPage: number) => {
    const offset = (newPage - 1) * config.ADMIN_EVENTS_LIST_LIMIT;
    setPage(newPage);
    setParameter({ offset });
  };

  React.useEffect(() => {
    const newPage = query.offset / config.ADMIN_EVENTS_LIST_LIMIT;
    setPage(newPage + 1);
    setList({
      listName: config.ADMIN_EVENTS_LIST_NAME,
      params: {
        ...query,
      },
    });
    commitList({
      listName: config.ADMIN_EVENTS_LIST_NAME,
      load: async () => {
        try {
          const res = await Api.adminGetEvents(appSpace, config.PROJECT_NAME, {
            offset: query.offset,
            limit: config.ADMIN_EVENTS_LIST_LIMIT,
          });
          const { result, entities } = normalizeEvents(res.events);

          updateEvents(entities);
          return {
            results: result,
            total: res.total,
          };
        } catch (e: any) {
          alert(__('Nepodarilo sa získať zoznam športových udalostí'));
          return {
            results: [],
            total: 0,
          };
        }
      },
    });
  }, [appSpace, query.offset]);

  return (
    <ScrollLayout
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              onClick={() =>
                router.push(`/admin/appSpace/${appSpace}/events/new`)
              }
              primary
            >
              {__('Vytvoriť novú aktivitu')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment
        loading={!!isFetching}
        header={
          <SegmentHeader withSeparator size="s">
            {__('Športové aktivity')}
          </SegmentHeader>
        }
      >
        <Segment raised>
          <BasicTable
            rows={events}
            rowKey="_id"
            columns={[
              { header: __('Dátum konania') },
              { header: __('Názov aktivity') },
              { header: __('Miesto konania') },
            ]}
            renderRow={(i) => [
              format(new Date(i.startDate), config.DATE_TIME_FORMAT),
              i.name,
              i.sportGround.name,
            ]}
            onClickRow={(i) =>
              router.push(`/admin/appSpace/${appSpace}/events/${i._id}`)
            }
          />
          <Paginator
            limit={config.ADMIN_EVENTS_LIST_LIMIT}
            page={page}
            onChangePage={onChangePage}
            total={total || 0}
            loading={!!isFetching}
          />
        </Segment>
      </Segment>
    </ScrollLayout>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AdminEventsList,
);
