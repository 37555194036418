import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import { QueryHocTypes } from '@sportnet/query-hoc';
import useQuery, { NumberParam } from '@sportnet/query-hoc/useQuery';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { commit, getListTotal, isCommiting } from '@sportnet/redux-list/ducks';
import Api from '../../api/Api';
import config from '../../config';
import { ReduxConnectProps, RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import __ from '../../utilities/__';
import { initializeOrSetListParams, updateEntities } from '../App/actions';
import { listSportGroundsSelector } from './selectors';

const mapStateToProps = (state: RootState) => ({
  sportGrounds: listSportGroundsSelector(state),
  total: getListTotal(config.ADMIN_SPORTGROUNDS_LIST_NAME)(state),
  isFetching: isCommiting(config.ADMIN_SPORTGROUNDS_LIST_NAME)(state),
});
const mapDispatchToProps = {
  setList: initializeOrSetListParams.action,
  commitList: commit.action,
  updateSportGrounds: updateEntities,
};

type Props = RouteProps<{ appSpace: string }> &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReduxConnectProps;

const AdminSportGroundsList: React.FC<Props> = ({
  setList,
  commitList,
  router,
  params: { appSpace },
  location: { search, pathname },
  updateSportGrounds,
  sportGrounds,
  isFetching,
  total,
}) => {
  const normalizeEvents = (data: any[]) => {
    return {
      entities: {
        sportGrounds: data.reduce((acc, item) => {
          return { ...acc, [String(item._id)]: item };
        }, {}) as { [key: string]: any },
      },
      result: data.map((item) => item._id),
    };
  };
  const { query } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    {
      parameters: {
        offset: NumberParam(0),
      },
    },
  );

  React.useEffect(() => {
    setList({
      listName: config.ADMIN_SPORTGROUNDS_LIST_NAME,
      params: {
        ...query,
      },
    });
    commitList({
      listName: config.ADMIN_SPORTGROUNDS_LIST_NAME,
      load: async () => {
        try {
          const res = await Api.adminGetSportGrounds(
            appSpace,
            config.PROJECT_NAME,
            {
              offset: query.offset,
              limit: config.ADMIN_SPORTGROUNDS_LIST_LIMIT,
            },
          );
          const { result, entities } = normalizeEvents(res.sportGrounds || []);

          updateSportGrounds(entities);
          return {
            results: result,
            total: 0,
          };
        } catch (e: any) {
          alert(__('Nepodarilo sa získať zoznam športových udalostí'));
          return {
            results: [],
            total: 0,
          };
        }
      },
    });
  }, [appSpace, query.offset]);

  return (
    <ScrollLayout
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              onClick={() =>
                router.push(`/admin/appSpace/${appSpace}/sportgrounds/new`)
              }
              primary
            >
              {__('Vytvoriť nové miesto konania')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment
        loading={!!isFetching}
        header={
          <SegmentHeader withSeparator size="s">
            {__('Miesta konania')}
          </SegmentHeader>
        }
      >
        <Segment raised>
          <BasicTable
            rows={sportGrounds}
            rowKey="_id"
            columns={[{ header: __('Názov') }]}
            renderRow={(i) => [i.name]}
            // onClickRow={i =>
            //   router.push(`/admin/${appSpace}/sportgrounds/${i._id}`)
            // }
          />
          {/* <Paginator
            limit={config.ADMIN_SPORTGROUNDS_LIST_LIMIT}
            page={page}
            onChangePage={onChangePage}
            total={total || 0}
            loading={!!isFetching}
          /> */}
        </Segment>
      </Segment>
    </ScrollLayout>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AdminSportGroundsList,
);
