import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { mb } from '@sportnet/ui/Themes/utilities';

const Wrapper = styled('div')<{ elMaxWidth?: number }>`
  padding: 0 ${rem(8)};
  ${mb('l')} {
    padding: 0 ${rem(64)};
  }
  max-width: ${({ elMaxWidth, theme }) =>
    rem(elMaxWidth || theme.app.maxWidth)};
  margin: 0 auto;
  width: 100%;
  flex: 1 0 0;
`;

const MaxWidthBox: React.FC<{
  width?: number;
  className?: string;
  children?: React.ReactNode;
}> = ({ children, width, className }) => {
  return (
    <Wrapper className={className} elMaxWidth={width}>
      {children}
    </Wrapper>
  );
};

export default MaxWidthBox;
