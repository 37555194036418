import { rem } from 'polished';
import { css } from 'styled-components';

/* Define the mixin */
const fluidTypography = (
  minFont: number,
  maxFont: number,
  minBreakpoint: number,
  maxBreakpoint: number,
) => {
  /* Define variable for media query */
  const maxLessOne = maxBreakpoint - 1;

  /* Define variable for fallback */
  const avg = (maxFont + minFont) / 2;

  /* Base font size */
  return css`
    font-size: ${rem(minFont)};
    @media (min-width: ${rem(minBreakpoint)}) and (max-width: ${rem(
        maxLessOne,
      )}) {
      /* Adds a fallback for unsupported browsers */
      font-size: ${rem(avg)};

      /* The fluid typography magic 🌟  */
      font-size: calc(
        ${rem(minFont)} + (${maxFont} - ${minFont}) *
          (100vw - ${rem(minBreakpoint)}) /
          (${maxBreakpoint} - ${minBreakpoint})
      );
    }

    @media (min-width: ${maxBreakpoint}px) {
      font-size: ${rem(maxFont)};
    }
  `;
};

export default fluidTypography;
