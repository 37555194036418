import { createGlobalStyle } from './styled-components';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap');
  html, body {
    margin: 0;
    font-family: 'Nunito Sans', sans-serif !important;
    font-size: 16px;
    background: #fff;
    height: 100%;
  }
  * {
    font-family: 'Nunito Sans', sans-serif !important;
  }
  #root {
    min-height: 100%;
  }
  div#menu-root div {
    z-index: 1000;
  }
  a {
    text-decoration: none;
  }
  * {
    box-sizing: border-box;
  }
  .leaflet-popup-content {
    margin: 0;
  }
  .leaflet-top,
  .leaflet-bottom {
    z-index: 5;
  }
  .leaflet-container > * {
    z-index: 0;
  }
  
`;
