import { mb, mediaSizes } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import FacebookIcon from '../../components/Icons/facebook';
import InstagramIcon from '../../components/Icons/instagram';
import config from '../../config';
import { RootState } from '../../configureStore';
import fluidTypography from '../../utilities/fluidTypography';
import CmsApi from '../../api/CmsApi';
import Widgets from '@sportnet/content';

const BrandsWrapper = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  margin: auto;
  padding: ${rem(16)};
  max-width: ${rem(config.CONTENT_MAX_WIDTH)};
`;

const Brands = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mb('l')} {
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    a {
      margin: 0;
    }
  }
`;

const CopyrightWrapper = styled.div`
  padding-bottom: ${rem(32)};
  ${mb('l')} {
    padding-bottom: ${rem(80)};
  }
`;

const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    padding: ${rem(4)} 0;
  }
  div,
  a {
    color: #000;
    ${fluidTypography(12, 16, mediaSizes.l, 1920)};
  }
`;

const Socials = styled.div`
  display: flex;
  margin: auto;
  padding-top: ${rem(48)};
  padding-bottom: ${rem(32)};
  ${mb('l')} {
    padding: ${rem(48)} 0;
  }
`;

const Social = styled.div`
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0 ${rem(2)} 0 rgba(0, 0, 0, 0.04);
  margin: 0 ${rem(8)};
  border-radius: 100%;
  width: ${rem(56)};
  height: ${rem(56)};
  ${mb('l')} {
    width: ${rem(72)};
    height: ${rem(72)};
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

type OwnProps = {};

const mapStateToProps = (state: RootState) => {
  return {};
};

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const Footer: React.FC<Props> = () => {
  const [content, setContent] = React.useState<any[]>([]);
  const getContent = React.useCallback(async () => {
    try {
      const res = await CmsApi.getPublicStaticContentById(
        'pages',
        config.APP_ID,
        'default',
        'partneri',
      );
      setContent(res.content || []);
    } catch (e: any) {
      //
    }
  }, []);

  React.useEffect(() => {
    getContent();
  }, [getContent]);

  return (
    <Wrapper>
      <Socials>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/grassrootsSFZ"
        >
          <Social>{FacebookIcon(24)}</Social>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/grassroots_sfz"
        >
          <Social>{InstagramIcon(24)}</Social>
        </a>
      </Socials>
      <Content>{!!content && <Widgets items={content} />}</Content>
      <BrandsWrapper>
        <Content>
          <Brands>
            <a
              rel="noopener noreferrer"
              href="http://www.futbalsfz.sk"
              target="_blank"
            >
              <div>
                <img
                  src="https://api.sportnet.online/data/ppo/futbalsfz.sk/logo"
                  alt="futbalsfz.sk"
                  width={48}
                  height={48}
                />
              </div>
            </a>
          </Brands>
        </Content>
      </BrandsWrapper>
      <CopyrightWrapper>
        <Content>
          <Copyright>
            <div>Copyright © DajmeSpoluGol.sk</div>
            <div>
              <a
                rel="noopener noreferrer"
                href="http://www.bart.sk"
                target="_blank"
              >
                Webdesign © bart.sk
              </a>
            </div>
          </Copyright>
        </Content>
      </CopyrightWrapper>
    </Wrapper>
  );
};

export default compose(connect(mapStateToProps))(Footer);
