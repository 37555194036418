import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Input from '@sportnet/ui/Input/index';
import { isAfter, isBefore } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { formatUserName, getProp } from 'sportnet-utilities';
import styled from 'styled-components';
import CoreApi, { Codelist } from '../../api/CoreApi';
import { RootState } from '../../configureStore';
import __ from '../../utilities/__';
import { required } from './validate';

const RELATIONS_PPO = 'futbalsfz.sk';

const LinkWrapper = styled.div<{ error?: string }>`
  margin-top: ${({ error }) => (error ? 0 : rem(-15))};
  margin-bottom: ${rem(15)};
  font-size: ${rem(12)};
  a:visited {
    color: inherit;
  }
`;

const CompetentPeopleForm: React.FC<any> = ({
  dispatch,
  form,
  licenseType,
  selectedLicenseType,
  ...props
}) => {
  const [isFetchingUser, setIsFetchingUser] = React.useState(false);
  const [shirtSizes, setShirtSizes] = React.useState<Codelist>({
    codelist: [],
  });
  const [isFetchingShirtSizes, setIsFetchingShirtSizes] = React.useState(false);

  const [licenses, setLicenses] = React.useState<string[]>([]);

  const getShirtSizes = React.useCallback(async () => {
    try {
      setIsFetchingShirtSizes(true);
      const codelist = await CoreApi.getCodelist('shirt-size');
      setShirtSizes(codelist);
    } catch (e: any) {
      //
    } finally {
      setIsFetchingShirtSizes(false);
    }
  }, []);

  React.useEffect(() => {
    getShirtSizes();
  }, [getShirtSizes]);

  const fetchUser = async (event: any) => {
    if (!event.target.value) {
      return;
    }
    try {
      setIsFetchingUser(true);
      const { users = [] } = await CoreApi.organizationPPOUsers(RELATIONS_PPO, {
        q: event.target.value,
      });
      if (users.length) {
        const userId = users[0]._id;
        const user = await CoreApi.organizationPPOUser(
          RELATIONS_PPO,
          userId || '',
        );
        dispatch(
          change(form, 'user', {
            sportnetId: user._id,
            name: formatUserName(user),
            license_type: selectedLicenseType,
          }),
        );
        const userLicenses = ((user as any).sport_expert_licenses ||
          []) as Array<{
          documentnr: string;
          type: string;
          valid_from: string;
          valid_to: string;
        }>;
        setLicenses(
          userLicenses
            .filter(
              (license) =>
                license.type === 'coach' &&
                isAfter(new Date(), new Date(license.valid_from)) &&
                isBefore(new Date(), new Date(license.valid_to)),
            )
            .map((license) => license.documentnr),
        );
      } else {
        throw new Error('USER_NOT_FOUND');
      }
    } catch (e: any) {
      console.error(e);
      alert(
        __('Nebolo možné získať detail užívateľa podľa zadaného sportnet ID'),
      );
      dispatch(change(form, 'user', {}));
    } finally {
      setIsFetchingUser(false);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <Field
          name="user.sportnetId"
          label={__('Sportnet ID')}
          component={FormFieldRedux}
          required
          validate={required}
          onBlur={fetchUser}
          loading={isFetchingUser}
        >
          <input />
          <Input.Button primary icon="search" onClick={fetchUser} />
        </Field>
        <LinkWrapper
          error={
            getProp(props, ['errors', 'user', 'sportnetId']) &&
            getProp(props, ['meta', 'user', 'sportnetId', 'touched'], false)
          }
        >
          <a
            href="https://my.sportnet.online"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            {__(
              'Nemá ešte účet zaregistrovaný v platforme Sportnet.Online? Zaregistrovať sa môže tu.',
            )}
          </a>
        </LinkWrapper>
      </Col>
      <Col xs={12}>
        <Field
          label={__('Meno a priezvisko')}
          name="user.name"
          component={FormFieldRedux}
          required
          disabled
          loading={isFetchingUser}
        />
      </Col>
      <Col xs={12}>
        <Field
          name="user.license_type"
          component={FormFieldRedux}
          label={__('Pozícia')}
          validate={[required]}
          type="theselect"
          required
          options={licenseType.map((license: any) => ({
            label: license.label,
            value: license.label,
          }))}
        />
      </Col>
      <Col xs={12}>
        <Field
          name="user.license_id"
          component={FormFieldRedux}
          label={__('Číslo licencie')}
          required
          validate={[required]}
          type="theselectsimple"
          options={licenses.map((l) => ({ label: l, value: l }))}
        />
      </Col>
      <Col xs={12}>
        <Field
          name="user.shirt_size"
          component={FormFieldRedux}
          label={__('Veľkosť oblečenia')}
          required
          validate={[required]}
          loading={isFetchingShirtSizes}
          type="theselect"
          options={(shirtSizes.codelist || []).map((i) => ({
            label: i.label || i.value,
            value: i.value,
          }))}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState, props: { form: string }) => {
  const selector = formValueSelector(props.form);
  return {
    selectedLicenseType: selector(state, 'user.license_type'),
  };
};

export default compose(
  reduxForm({}),
  connect(mapStateToProps),
)(CompetentPeopleForm) as any;
