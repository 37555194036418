import { getListResults } from '@sportnet/redux-list/ducks';
import { createSelector } from 'reselect';
import at from 'sportnet-utilities/lib/at';
import config from '../../config';
import { RootState } from '../../configureStore';
import { entitiesSelector } from '../App/selectors';

const organizationDetailByIdDomain = (state: RootState) =>
  state.detail.organizations;

const currentOrganizationDetailDomain = (id: string) =>
  createSelector(organizationDetailByIdDomain, (byId) => {
    const item = byId[id];
    if (item) {
      return item;
    }
    return null;
  });

export const currentOrganizationIsFetchingSelector = (id: string) =>
  createSelector(currentOrganizationDetailDomain(id), (domain) =>
    domain ? !!domain.isFetching : false,
  );

export const currentOrganizationSelector = (id: string) =>
  createSelector(entitiesSelector, (entities) =>
    id !== null && id in entities.organizations
      ? entities.organizations[id]
      : null,
  );

export const listEventsSelector = createSelector(
  entitiesSelector,
  getListResults(config.ORGANIZATION_EVENTS_LIST_NAME),
  (entities, ids) => at(entities.events, ids || []),
);

export const listSeasonsSelector = createSelector(
  entitiesSelector,
  getListResults(config.SEASONS_LIST_NAME),
  (entities, ids) => at(entities.seasons, ids || []),
);

export const listTeamsSelector = createSelector(
  entitiesSelector,
  getListResults(config.ORGANIZATION_TEAMS_LIST_NAME),
  (entities, ids) => at(entities.teams, ids || []),
);

export const listOrganizationsSelector = createSelector(
  entitiesSelector,
  getListResults(config.ORGANIZATIONS_LIST_NAME),
  (entities, ids) => at(entities.organizations, ids || []),
);
