import NotFoundComponent from '@sportnet/ui/NotFound';
import { rem } from 'polished';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import EntryAnimation from '../../components/EntryAnimation';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';
import { Wrapper } from '../App';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 0 ${rem(10)};
  text-align: center;
`;

class NotFoundPage extends React.PureComponent<{}> {
  render() {
    return (
      <Wrapper>
        <ContentWrapper>
          <EntryAnimation key={'not-found-page'}>
            <ThemeProvider theme={{ inactiveColor: '#b5b5b5' }}>
              <NotFoundComponent
                title={__('Stránka neexistuje')}
                icon="error"
              />
            </ThemeProvider>
          </EntryAnimation>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

export default NotFoundPage;
