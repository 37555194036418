import { mb } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import Footer from '../Footer';
import Header from '../Header';

const Wrapper = styled.div`
  min-height: 100vh;
  // background: ${({ theme }) => theme.color.primary};
  background-image: url('/background-pattern@1x.png');
  background-image: image-set(
    url('/background-pattern@1x.png') 1x,
    url('/background-pattern@2x.png') 2x
  );
  background-size: 270px;
  ${mb('l')} {
    background-size: ${rem(540)};
  }
`;

const Body = styled.div``;

type Props = {
  children?: React.ReactNode;
};

const Page: React.FC<Props> = ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <Body>{children}</Body>
      <Footer />
    </Wrapper>
  );
};

export default Page;
