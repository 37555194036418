import React from 'react';

const ChevronLeftIcon = (
  width: number = 8,
  height: number = 12,
  color: string = '#000',
) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 8 12"
      xmlSpace="preserve"
      width={width}
      height={height}
    >
      <path fill={color} d="M7.7,1.4L3.1,6l4.6,4.6L6.3,12l-6-6l6-6L7.7,1.4z" />
    </svg>
  );
};

export default ChevronLeftIcon;
