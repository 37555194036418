import TheLayout from '@sportnet/ui/TheLayout';
import {
  INavigationItem,
  INavigationSubItem,
} from '@sportnet/ui/TheLayout/Navigation';
import { rem } from 'polished';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { compose } from 'redux';
import styled from 'styled-components';
import config from '../../config';
import { RootState } from '../../configureStore';
import {
  activeAppspaceNameSelector,
  activeAppspaceSelector,
  // applicationInfoSelector,
  authUserSelector,
} from '../../pages/App/selectors';
import __ from '../../utilities/__';

const StyledLink = styled(Link)`
  padding: 0 ${({ theme }) => rem(theme.grid.gutterWidth)};
  color: ${({ theme }) => theme.textColor};
  text-decoration: none;
  display: block;
  width: 100%;

  :hover,
  :active,
  :focus {
    color: ${({ theme }) => theme.color.primary};
    background: ${({ theme }) => theme.color.primaryBg};
  }
  > div {
    padding: ${rem(8)} 0;
    display: flex;
    align-items: center;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - ${rem(config.HEADER_HEIGHT)});
  flex: 1 0 0;
  > div {
    width: 100%;
  }
`;

interface OwnProps {
  topFixed?: JSX.Element;
  bottomFixed?: JSX.Element;
  isFetching?: boolean | null;
  withoutMenu?: boolean;
  children?: React.ReactNode;
}

const mapStateToProps = (state: RootState) => ({
  appspace: activeAppspaceSelector(state),
  organizationName: activeAppspaceNameSelector(state),
  // application: applicationInfoSelector(state),
  user: authUserSelector(state) as any,
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & IMapStateToProps;

class Layout extends React.PureComponent<
  Props,
  {
    sidebarVisible: boolean;
    menuItems: INavigationItem[];
  }
> {
  static contextTypes = {
    logout: PropTypes.func,
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      sidebarVisible: false,
      menuItems: [
        {
          url: this.getMenuItemUrl('events'),
          label: __('Športové aktivity'),
          subitems: [],
        },
        {
          url: this.getMenuItemUrl('sportgrounds'),
          label: __('Miesta konania'),
          subitems: [],
        },
        {
          url: this.getMenuItemUrl('teams'),
          label: __('Triedy'),
          subitems: [],
        },
      ],
    };
  }

  getMenuItemUrl = (menuItemUrl: string) => {
    const { appspace } = this.props;
    if (appspace) {
      return `/admin/appSpace/${appspace.app_space}/${menuItemUrl}`;
    }
    return menuItemUrl;
  };

  render() {
    const {
      bottomFixed,
      children,
      organizationName,
      appspace,
      topFixed,
      user,
      withoutMenu,
    } = this.props;

    const settings = {
      ...(!withoutMenu && { navigation: this.state.menuItems }),
      renderNavigationLink: (renderProps: {
        item: INavigationItem | INavigationSubItem;
        linkProps: {
          className: string;
          onClick?: () => void;
          children: React.ReactNode;
        };
      }) => {
        const {
          item: { url },
          linkProps,
        } = renderProps;

        return (
          <StyledLink {...linkProps} to={url || ''}>
            <div>{linkProps.children}</div>
          </StyledLink>
        );
      },
      bottom: <div />,
      top: <div />,
      name: appspace && appspace.display_name,
      onLogout: (this.context as any).logout,
      userOrganization: organizationName || '',
      userOrganizationProfile: appspace ? appspace.org_profile.name : '',
      userName: user ? `${user.name} ${user.surname}` : 'Používateľ aplikácie',
      userPictureSrc:
        user && user.photo && user.photo.public_url
          ? user.photo.public_url
          : undefined,
      notificationCount: user ? user.messages : 0,
    };
    if (topFixed) {
      settings.top = topFixed;
    }
    if (bottomFixed) {
      settings.bottom = bottomFixed;
    }

    return (
      <Wrapper>
        <TheLayout {...settings}>{children}</TheLayout>
      </Wrapper>
    );
  }
}

export default compose(connect(mapStateToProps))(Layout);
