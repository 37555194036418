import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { listReducer } from '@sportnet/redux-list/ducks';
import {
  applicationReducer,
  detailReducerFromAction,
  entitiesReducer,
} from './pages/App/reducer';
import { appSettingsReducer } from './pages/DomainResolver/reducer';
import { loadEvent } from './pages/Events/actions';
import { loadOrganization } from './pages/Schools/actions';
import { loadUser } from './pages/Users/actions';

const rootReducer = combineReducers({
  entities: entitiesReducer,
  list: listReducer,
  application: applicationReducer,
  settings: appSettingsReducer,
  form: formReducer,
  detail: combineReducers({
    organizations: detailReducerFromAction([loadOrganization], 'id'),
    users: detailReducerFromAction([loadUser], 'id'),
    events: detailReducerFromAction([loadEvent], 'id'),
  }),
});

export default rootReducer;
