import { getListResults } from '@sportnet/redux-list/ducks';
import { createSelector } from 'reselect';
import at from 'sportnet-utilities/lib/at';
import config from '../../config';
import { RootState } from '../../configureStore';
import { entitiesSelector } from '../App/selectors';

const userDetailByIdDomain = (state: RootState) => state.detail.users;

const currentUserDetailDomain = (id: string) =>
  createSelector(userDetailByIdDomain, (byId) => {
    const item = byId[id];
    if (item) {
      return item;
    }
    return null;
  });

export const currentUserIsFetchingSelector = (id: string) =>
  createSelector(currentUserDetailDomain(id), (domain) =>
    domain ? !!domain.isFetching : false,
  );

export const currentUserSelector = (id: string) =>
  createSelector(entitiesSelector, (entities) =>
    id !== null && id in entities.users ? entities.users[id] : null,
  );

export const listUsersSelector = createSelector(
  entitiesSelector,
  getListResults(config.USERS_LIST_NAME),
  (entities, ids) => at(entities.users, ids || []),
);

export const listEventsSelector = createSelector(
  entitiesSelector,
  getListResults(config.USER_EVENTS_LIST_NAME),
  (entities, ids) => at(entities.events, ids || []),
);
