import React from 'react';

const ChevronRightIcon = (
  width: number = 8,
  height: number = 12,
  color: string = '#000',
) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 8 12"
      xmlSpace="preserve"
      width={width}
      height={height}
    >
      <path fill={color} d="M0.3,1.4L4.9,6l-4.6,4.6L1.7,12l6-6l-6-6L0.3,1.4z" />
    </svg>
  );
};

export default ChevronRightIcon;
