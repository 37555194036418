import Col, { Row } from '@sportnet/ui/Grid';
import Icon from '@sportnet/ui/Icon';
import Loader from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import { mb } from '@sportnet/ui/Themes/utilities';
import Widgets from '@sportnet/content/view';
import { format } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { compose } from 'redux';
import PublicPage from '../../components/PublicPage';
import { RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';
import { loadOrganization } from '../Schools/actions';
import {
  currentOrganizationIsFetchingSelector,
  currentOrganizationSelector,
} from '../Schools/selectors';
import UsersTable from '../Users/UsersTable';
import { loadEvent, loadNomination } from './actions';
import AvatarsIcon from './Avatars';
import {
  currentEventIsFetchingSelector,
  currentEventSelector,
  listNominationSelector,
} from './selectors';

const mapStateToProps = (
  state: RootState,
  props: RouteProps<{ id: string; appSpace: string }>,
) => ({
  event: currentEventSelector(props.params.id)(state),
  isFetchingEvent: currentEventIsFetchingSelector(props.params.id)(state),
  organization: currentOrganizationSelector(props.params.appSpace)(state),
  isFetchingOrganization: currentOrganizationIsFetchingSelector(
    props.params.appSpace,
  )(state),
  athletes: listNominationSelector(state),
});
const mapDispatchToProps = {
  loadEventItem: loadEvent.action,
  loadOrganizationItem: loadOrganization.action,
  loadNominationItem: loadNomination.action,
};

const OrganizationInfo = styled.div`
  display: flex;
  align-items: center;
`;

const OrganizationName = styled.div`
  font-size: ${rem(24)};
  font-weight: bold;
  padding-left: ${rem(16)};
`;

const OrganizationType = styled.div`
  font-size: ${rem(14)};
  font-weight: 200;
  display: block;
  a {
    color: #333;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${rem(8)} 0;
`;

const InfoItemIcon = styled.div`
  display: flex;
  padding-right: ${rem(16)};
`;

const InfoItemContent = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 300;
  > div:first-child {
    padding-right: ${rem(4)};
  }
`;

const InfoItem = styled.div`
  font-size: ${rem(14)};
  display: flex;
  // height: ${rem(60)};
  padding: ${rem(8)} 0;
  ${InfoItemContent} {
    justify-content: space-around;
  }
  ${mb('s')} {
    padding: 0;
    ${InfoItemIcon} {
      align-items: flex-start;
    }
    ${InfoItemContent} {
      justify-content: flex-start;
    }
  }
`;

const InfoItemLabel = styled.div`
  // color: #999;
  font-weight: bold;
  padding-bottom: ${rem(4)};
`;

type Props = RouteProps<{ id: string; appSpace: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const EventDetail: React.FC<Props> = ({
  params: { id, appSpace },
  loadEventItem,
  loadOrganizationItem,
  loadNominationItem,
  event,
  isFetchingEvent,
  isFetchingOrganization,
  organization,
  athletes,
}) => {
  const fetchData = React.useCallback(async () => {
    try {
      await Promise.all([
        loadEventItem({ id }),
        loadNominationItem({ id }),
        loadOrganizationItem({ id: appSpace }),
      ]);
    } catch (e: any) {
      alert(__('Nepodarilo sa získať informácie o aktivite.'));
    } finally {
      //
    }
  }, [id, appSpace]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [Leaflet, setLeaflet] = React.useState<any>(null);

  React.useEffect(() => {
    if (window) {
      const component = require('react-leaflet');
      setLeaflet({
        Map: component.Map,
        Marker: component.Marker,
        TileLayer: component.TileLayer,
      });
    }
  }, []);

  const title = __('Informácie o aktivite');

  return (
    <PublicPage title={title} metaTitle={event ? event.name : title}>
      {(isFetchingEvent || isFetchingOrganization) && (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      )}
      {!isFetchingEvent && !isFetchingOrganization && event && organization && (
        <>
          <Segment raised>
            <OrganizationInfo>
              <div>
                <AvatarsIcon />
              </div>
              <OrganizationName>
                <div>{event.name}</div>
                <OrganizationType>
                  <Link to={`/skola/${organization._id}`}>
                    {organization.name}
                  </Link>
                </OrganizationType>
              </OrganizationName>
            </OrganizationInfo>
          </Segment>
          <Segment raised>
            {!!Leaflet && (
              <Leaflet.Map
                style={{ height: rem(250) }}
                center={event.sportGround.geo.coordinates}
                zoom={16}
              >
                <Leaflet.TileLayer
                  url={`https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=${process.env.REACT_APP_MAPTILER_API_KEY}`}
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  tileSize={512}
                  zoomOffset={-1}
                />
                <Leaflet.Marker position={event.sportGround.geo.coordinates} />
              </Leaflet.Map>
            )}
          </Segment>
          <Segment
            raised
            // header={
            //   <SegmentHeader size="s" withSeparator>
            //     {__('Miesto a čas konania')}
            //   </SegmentHeader>
            // }
          >
            <Row>
              <Col xs={12} s={3}>
                <InfoItem>
                  <InfoItemIcon>
                    <Icon color="#333" size={32} name="calendar" />
                  </InfoItemIcon>
                  <InfoItemContent>
                    <InfoItemLabel>{__('Dátum')}</InfoItemLabel>
                    <div>{format(new Date(event.startDate), 'dd.MM.yyyy')}</div>
                  </InfoItemContent>
                </InfoItem>
              </Col>
              <Col xs={12} s={3}>
                <InfoItem>
                  <InfoItemIcon>
                    <Icon color="#333" size={32} name="notification" />
                  </InfoItemIcon>
                  <InfoItemContent>
                    <InfoItemLabel>{__('Čas')}</InfoItemLabel>
                    <div>
                      {format(new Date(event.startDate), 'HH:mm')} -{' '}
                      {format(new Date(event.endDate), 'HH:mm')}
                    </div>
                  </InfoItemContent>
                </InfoItem>
              </Col>
              <Col xs={12} s={3}>
                <InfoItem>
                  <InfoItemIcon>
                    <Icon color="#333" size={32} name="place" />
                  </InfoItemIcon>
                  <InfoItemContent>
                    <InfoItemLabel>{__('Adresa')}</InfoItemLabel>

                    <div>{event.sportGround.name}</div>
                  </InfoItemContent>
                </InfoItem>
              </Col>
              <Col xs={12} s={3}>
                <InfoItem>
                  <InfoItemIcon>
                    <Icon color="#333" size={32} name="group" />
                  </InfoItemIcon>
                  <InfoItemContent>
                    <InfoItemLabel>{__('Počet žiakov')}</InfoItemLabel>

                    <div>{athletes.length}</div>
                  </InfoItemContent>
                </InfoItem>
              </Col>
            </Row>
            {/* <Table>
              <Tbody>
                <StyledTr>
                  <Td>
                    <b>{__('Miesto konania')}</b>
                  </Td>
                  <Td>{event.sportGround.name}</Td>
                </StyledTr>
                <StyledTr>
                  <Td>
                    <b>{__('Čas konania')}</b>
                  </Td>
                  <Td>{`${format(
                    new Date(event.startDate),
                    config.DATE_TIME_FORMAT,
                  )} - ${format(
                    new Date(event.endDate),
                    isSameDay(event.startDate, event.endDate)
                      ? 'HH:mm'
                      : config.DATE_TIME_FORMAT,
                  )}`}</Td>
                </StyledTr>
              </Tbody>
            </Table> */}
          </Segment>
          {event.description && event.description.widgets.length > 0 && (
            <Segment raised>
              <Widgets items={event.description.widgets} />
            </Segment>
          )}
          {athletes && athletes.length > 0 && (
            <Segment
              raised
              header={
                <SegmentHeader size="s" withSeparator>
                  {__('Zoznam žiakov')}
                </SegmentHeader>
              }
            >
              <UsersTable items={athletes} />
            </Segment>
          )}
        </>
      )}
    </PublicPage>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EventDetail,
);
