import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { RootState } from '../../configureStore';
import { appSettingsSelector } from '../../pages/DomainResolver/selectors';
import { withTheme } from '../../theme/styled-components';
import TagManager from 'react-gtm-module';
import __ from '../../utilities/__';
import ReactGA from 'react-ga';

const mapStateToProps = (state: RootState) => ({
  appSettings: appSettingsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = IMapStateToProps & RouteComponentProps<void, void>;

const Analytics: React.FC<Props> = ({ appSettings }) => {
  if (appSettings?.googleTagmanager?.id) {
    TagManager.initialize({ gtmId: appSettings.googleTagmanager.id });
  }

  if (appSettings?.googleAnalytics?.id) {
    ReactGA.initialize(appSettings.googleAnalytics.id);
  }

  return null;
};

export default compose(withTheme, connect(mapStateToProps))(Analytics);
