import { commit } from '@sportnet/redux-list/ducks';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { ExtraArgumentType, RootState } from '../../configureStore';
import ForbiddenError from '../../ForbiddenError';
import InternalServerError from '../../InternalServerError';
import { NormalizedEntities } from '../../library/App';
import NotFoundError from '../../NotFoundError';
import UnauthorizedError from '../../UnauthorizedError';
import normalizeEntities from '../../utilities/normalizeEntities';
import __ from '../../utilities/__';
import { initializeOrSetListParams, updateEntities } from '../App/actions';
const create = actionCreatorFactory('ARTICLE');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadEvent = createAsync<
  { id: string },
  NormalizedEntities<'events'>
>('LOAD_ORGANIZATION', async ({ id }, dispatch, getState, { Api }) => {
  try {
    const response = await Api.publicGetEvent(id, config.PROJECT_NAME);
    return normalizeEntities('events', [response]);
  } catch (e: any) {
    if (e && e.details && e.details.code === 401) {
      throw new UnauthorizedError(e);
    }
    if (e && e.details && e.details.code === 403) {
      throw new ForbiddenError(e);
    }
    if (e && e.details && e.details.code === 404) {
      throw new NotFoundError(e);
    }
    throw new InternalServerError(e);
  }
});

const normalizeNomination = (data: any[]) => {
  return {
    entities: {
      users: data.reduce((acc, item) => {
        return { ...acc, [String(item._id)]: item };
      }, {}) as { [key: string]: any },
    },
    result: data.map((item) => item._id),
  };
};

export const loadNomination = createAsync<
  { id: string },
  NormalizedEntities<'users'>
>('LOAD_NOMINATION', async ({ id }, dispatch, getState, { Api }) => {
  try {
    const response = await Api.publicGetEventNomination(
      config.PROJECT_NAME,
      id,
    );
    const athletes = response.athletes.map((i) => ({
      ...i.sportnetUser,
      ...i.additionalData,
      orgProfileId: response.appSpace,
    }));
    dispatch(
      initializeOrSetListParams.action({
        listName: config.NOMINATION_LIST_NAME,
        params: {
          id,
        },
      }),
    );
    await dispatch(
      commit.action({
        listName: config.NOMINATION_LIST_NAME,
        load: async () => {
          try {
            const { result, entities } = normalizeNomination(athletes);

            updateEntities(entities);
            return {
              results: result,
              total: 0,
            };
          } catch (e: any) {
            alert(__('Nepodarilo sa získať zoznam žiakov'));
            return {
              results: [],
              total: 0,
            };
          }
        },
      }),
    );
    return normalizeEntities('users', athletes);
  } catch (e: any) {
    if (e && e.details && e.details.code === 401) {
      throw new UnauthorizedError(e);
    }
    if (e && e.details && e.details.code === 403) {
      throw new ForbiddenError(e);
    }
    throw new InternalServerError(e);
  }
});

// export const loadNomination = createAsync<
// { id: string },
// NormalizedEntities<'users'>
// >('LOAD_NOMINATION', async ({ id }, dispatch, getState, { Api }) => {
//   try {
//     const response = await Api.publicGetEventNomination(id);
//     const athletes = response.athletes.map(i => ({
//       ...i.sportnetUser,
//       ...i.additionalData,
//       orgProfileId: response.appSpace,
//     }));
//     dispatch(
//       initializeOrSetListParams.action({
//         listName: config.NOMINATION_LIST_NAME,
//         params: { id },
//       }),
//     );
//     await commit.action({
//       listName: config.NOMINATION_LIST_NAME,
//       load: async () => {
//         const { entities, result } = normalizeNomination(athletes);
//         dispatch(updateEntities(entities));
//         return {
//           results: result,
//           total: 0,
//         };
//       },
//     });
//     return normalizeEntities('users', athletes);
//     // const response = await Api.publicGetEventNomination(id);
//     // return normalizeEntities(
//     //   'users',
//     //   response.athletes.map(i => ({
//     //     ...i.sportnetUser,
//     //     ...i.additionalData,
//     //     orgProfileId: response.appSpace,
//     //   })),
//     // );
//   } catch (e: any) {
//     return {
//       results: [],
//       total: 0
//     }
//   }
// });
