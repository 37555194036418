import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { submit } from 'redux-form';
import Api from '../../api/Api';
import config from '../../config';
import { ReduxConnectProps } from '../../configureStore';
import { RouteProps } from '../../library/App';
import __ from '../../utilities/__';
import AdminTeamForm from './form';
import { ITeam } from './types';

const FORM_NAME = 'TEAM_FORM';

type Props = RouteProps<{ appSpace: string; id: string }> & ReduxConnectProps;

const AdminTeam: React.FC<Props> = ({
  params: { appSpace, id },
  router,
  dispatch,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState<null | ITeam>(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const getTeam = React.useCallback(async () => {
    if (id !== 'new') {
      setIsFetching(true);
      try {
        const res = await Api.adminGetTeamById(
          appSpace,
          config.PROJECT_NAME,
          id,
        );
        setInitialValues(res);
      } catch (e: any) {
        alert(__('Nepodarilo sa získať podrobnosti aktivity'));
      } finally {
        setIsFetching(false);
      }
    }
  }, [appSpace, id]);

  React.useEffect(() => {
    getTeam();
  }, [getTeam]);

  const onSubmit = async (data: any) => {
    const submitData = {
      name: data.name,
    };
    try {
      setIsSubmitting(true);
      if (id !== 'new') {
        // update
        await Api.adminUpdateTeam(
          appSpace,
          config.PROJECT_NAME,
          id,
          undefined,
          submitData,
        );
      } else {
        const res = await Api.adminCreateTeam(
          appSpace,
          config.PROJECT_NAME,
          undefined,
          submitData,
        );
        router.push(`/admin/appSpace/${appSpace}/teams/${res._id}`);
      }
    } catch (e: any) {
      alert(__('Zmeny sa nepodarilo uložiť'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteTeam = async () => {
    if (window.confirm(__('Skutočne si želáte zmazať triedu?'))) {
      try {
        setIsDeleting(true);
        await Api.adminDeleteTeam(appSpace, config.PROJECT_NAME, id);
        router.push(`/admin/appSpace/${appSpace}/teams`);
      } catch (e: any) {
        alert(__('Triedu sa nepodarilo zmazať.'));
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <ScrollLayout
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              onClick={() => {
                dispatch(submit(FORM_NAME));
              }}
              loading={isSubmitting}
              primary
            >
              {__('Uložiť')}
            </Button>
          </ContextBarItem>
          {id !== 'new' && (
            <>
              <Button loading={isDeleting} onClick={deleteTeam} danger>
                {__('Zmazať')}
              </Button>
            </>
          )}
        </ContextBar>
      }
    >
      <Segment loading={isFetching}>
        {!isFetching && (
          <AdminTeamForm
            onSubmit={onSubmit}
            form={FORM_NAME}
            initialValues={initialValues || {}}
            competitionId={initialValues?.competitionId}
            partId={initialValues?.partId}
          />
        )}
      </Segment>
    </ScrollLayout>
  );
};

export default compose(connect(), withRouter)(AdminTeam);
