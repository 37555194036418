import * as React from 'react';
import { UnmountClosed } from 'react-collapse';
import { emphasizeColor, mb } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import EventStack from '@sportnet/ui/EventStack';
import Icon from '@sportnet/ui/Icon';
import MediaManagerImage from '@sportnet/ui/MediaManagerImage';
import ReactDOM from 'react-dom';
import SiteUrl from './siteUrl';
import styled, {
  createGlobalStyle,
  css,
  Theme,
  withTheme,
} from '@sportnet/ui/Themes/styled-components';
import __ from '../../utilities/__';
import FacebookIcon from '../Icons/facebook';
import InstagramIcon from '../Icons/instagram';
import config from '../../config';

const linkClass = 'responsive-menu-link';
const WIDTH_RESPONSIVE_MENU = 312;

type Position = 'right' | 'left';

const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const WrapperSection = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  font-size: ${rem(16)};
  font-weight: bold;
`;
const Overlay = styled.div<{ $visible?: boolean; $position?: Position }>`
  position: fixed;
  z-index: 121;
  overflow-y: auto;

  -webkit-overflow-scrolling: touch;
  flex-wrap: wrap;
  display: flex;
  top: 0;
  transition: all 0.35s;
  ${({ $position, $visible }) => {
    if ($position === 'left') {
      return css`
        left: ${$visible ? '0%' : '-100%'};
      `;
    } else {
      return css`
        right: ${$visible ? '0%' : '-100%'};
      `;
    }
  }}
  width: 85%;
  height: 100%;
  background-image: url('/background-pattern-menu@2x.png');
  background-size: 270px;
  ${mb('xs')} {
    ${({ $visible }) =>
      $visible &&
      css`
        width: ${rem(WIDTH_RESPONSIVE_MENU)};
      `}
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const CloseButtonContainer = styled.div`
  height: 50px;
  width: 85%;
`;

const CloseButtonFixed = styled.div`
  padding: 0 ${rem(24)};
  height: ${rem(config.MOBILE_HEADER_HEIGHT)};
  position: fixed;
  width: 85%;
  ${mb('xs')} {
    width: ${rem(WIDTH_RESPONSIVE_MENU)};
  }
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.div<{ $position?: Position }>`
  width: ${rem(26)};
  display: flex;
  ${({ $position }) => {
    if ($position === 'right') {
      return css`
        margin-left: auto;
        width: ${rem(26)};
        display: block;
      `;
    } else {
      return css``;
    }
  }}
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
`;
const SIcon = styled(Icon)`
  margin-right: 10px;
  cursor: pointer;
`;
const ContentWrapper = styled.div`
  width: 100%;
  .ReactCollapse--collapse {
    transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .${linkClass} {
    width: 100%;
    border: none;
    font: inherit;
    background: none;
    cursor: pointer;
    display: flex;
    padding: ${rem(12)} 0;
    color: ${({ theme }) => theme.responsiveMenu.linkColor};
    font-weight: normal;
    text-decoration: none;
    outline: none;
    align-items: center;
  }

  /* level 1 */
  & > ul {
    margin-top: 0;
    margin: 0;
    & > li {
      & > .${linkClass} {
        display: flex;
        align-items: center;
      }
      & > div > .${linkClass} {
        display: flex;
        align-items: center;
      }
    }
  }

  /* level 2 */
  & > ul > li > div > div > ul {
    background: ${({ theme }) =>
      emphasizeColor(0.02, `${theme.responsiveMenu.backgroundColor}`)};
    & > li > div > div > ul {
      margin: 0;
      background: ${({ theme }) =>
        emphasizeColor(0.06, `${theme.responsiveMenu.backgroundColor}`)};
    }
    & > li > .${linkClass} {
      font-size: ${rem(13)};
      font-weight: bold;
      color: ${({ theme }) => theme.responsiveMenu.linkColorHighlighted};
    }
    & > li > div > .${linkClass} {
      font-size: ${rem(13)};
      font-weight: bold;
      color: ${({ theme }) => theme.responsiveMenu.linkColorHighlighted};
    }
  }
`;

const ListWrapper = styled.ul`
  display: block;
  padding: 0 ${rem(24)};
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > {
    :focus,
    :hover {
      background: ${({ theme }) =>
        emphasizeColor(0.09, `${theme.responsiveMenu.backgroundColor}`)};
    }
  }
`;

const List = styled.li`
  list-style-type: none;
  font-weight: bold;
  text-align: center;
  font-size: ${rem(14)};
`;

interface ISidebarBackdrop {
  $isOpen: boolean;
}

const SidebarBackdrop = styled('div')<ISidebarBackdrop>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 120;
  transition: all 0.35s;
  background-color: ${({ theme }) => theme.responsiveMenu.backdropColor};
  ${({ $isOpen }) => {
    if ($isOpen) {
      return css`
        opacity: 1;
      `;
    } else {
      return css`
        pointer-events: none;
        opacity: 0;
      `;
    }
  }}
`;

const Title = styled.div`
  font-size: ${rem(18)};
  font-weight: bold;
`;

const MenuWrapper = styled.div`
  height: calc(100% - ${rem(config.MOBILE_HEADER_HEIGHT)});
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Socials = styled.div`
  display: flex;
  padding-top: ${rem(36)};
`;

const Social = styled.div`
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
  margin: 0 ${rem(8)};
  border-radius: 100%;
  width: ${rem(56)};
  height: ${rem(56)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledMediaManagerImage = styled(MediaManagerImage)`
  width: 24px;
  flex-shrink: 0;
  margin-right: 13px;
`;

const bodyOverflowHiddenClass = 'responsive-menu-opened';

interface Section {
  name: string;
  _id: string | number;
  url?: string;
  sections?: Section[];
  icon?: any;
}

interface OwnProps {
  sections: Section[];
  onClose: () => void;
  /**
   * @legacy use renderLink instead
   */
  onClickLink?: (section: Section) => void;
  renderLink?: (renderProps: {
    section: Section;
    linkProps: {
      className: string;
      onClick: () => void;
      children?: React.ReactNode;
    };
    level: number;
  }) => React.ReactNode;
  visible?: boolean;
  siteUrl?: string;
  depthLevel?: number;
  position?: Position;
}

const defaultProps = {
  depthLevel: 3,
  position: 'right' as const,
};

type Props = OwnProps & Theme;

let element = null;
let menuRoot: HTMLElement;
if (typeof document !== 'undefined') {
  element = document.createElement('div');
  element.setAttribute('id', 'menu-root');
  menuRoot = document.body.appendChild(element);
}

interface IState {
  active: boolean;
  openedSubmenuIdx: any[];
}

const GlobalStyle = createGlobalStyle`
  body.${bodyOverflowHiddenClass} {
    overflow: hidden;
  }
`;

export class ResponsiveMenu extends React.PureComponent<Props, IState> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);
    this.state = {
      active: false,
      openedSubmenuIdx: [],
    };
  }

  componentDidMount() {
    this.setState({ active: true });

    if (this.props.visible === true) {
      this.init();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { visible } = this.props;
    if (typeof document !== 'undefined') {
      if (visible === true && prevProps.visible === false) {
        this.init();
      }
      if (visible === false && prevProps.visible === true) {
        this.destroy();
      }
    }
  }

  componentWillUnmount() {
    this.destroy();
  }

  init = () => {
    document.body.classList.add(bodyOverflowHiddenClass);
    if (typeof document !== 'undefined') {
      EventStack.add(document.body, 'keydown', this.handleKeyDown);
    }
  };

  destroy = () => {
    document.body.classList.remove(bodyOverflowHiddenClass);
    if (typeof document !== 'undefined') {
      EventStack.remove(document.body, 'keydown', this.handleKeyDown);
    }
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { onClose } = this.props;
    if (e.key === 'Escape') {
      onClose();
    }
  };

  handeleCollapse = (id: any) => {
    if (this.state.openedSubmenuIdx.includes(id)) {
      const newOpenedSubmenuIdx = [...this.state.openedSubmenuIdx];
      const index = newOpenedSubmenuIdx.indexOf(id);
      if (index !== -1) {
        newOpenedSubmenuIdx.splice(index, 1);
        this.setState({ openedSubmenuIdx: newOpenedSubmenuIdx });
      }
    } else {
      this.setState({
        openedSubmenuIdx: [...this.state.openedSubmenuIdx, id],
      });
    }
  };

  itemRenderer = (section: Section, level: number, color: string) => {
    const { depthLevel, renderLink, onClickLink, onClose } = this.props;
    const linkChildren = (
      <>
        {section.icon && (
          <StyledMediaManagerImage
            picture={section.icon}
            naturalHeight={24}
            naturalWidth={24}
          />
        )}
        <WrapperSection>{section.name}</WrapperSection>
      </>
    );
    const linkChildrenWoIcon = <WrapperSection>{section.name}</WrapperSection>;

    return (
      <List key={String(section._id)}>
        {!section.sections ||
        section.sections.length === 0 ||
        level === depthLevel ? (
          renderLink ? (
            renderLink({
              section,
              linkProps: {
                className: linkClass,
                onClick: onClose,
                children: linkChildren,
              },
              level,
            })
          ) : onClickLink ? (
            <button className={linkClass} onClick={() => onClickLink(section)}>
              {linkChildren}
            </button>
          ) : (
            <span className={linkClass}>{linkChildren}</span>
          )
        ) : (
          <WrapperRow
            onClick={() => {
              this.handeleCollapse(section._id);
            }}
          >
            <button className={linkClass}>{linkChildren}</button>
            {this.state.openedSubmenuIdx.includes(section._id) ? (
              <SIcon name="arrow-down" color={color} />
            ) : (
              <SIcon name="arrow-right" color={color} />
            )}
          </WrapperRow>
        )}

        {section.sections &&
          section.sections.length > 0 &&
          level < depthLevel! && (
            <UnmountClosed
              isOpened={this.state.openedSubmenuIdx.includes(section._id)}
            >
              <ListWrapper>
                {renderLink
                  ? renderLink({
                      section,
                      linkProps: {
                        className: linkClass,
                        onClick: onClose,
                        children: linkChildrenWoIcon,
                      },
                      level,
                    })
                  : onClickLink && (
                      <button
                        className={linkClass}
                        onClick={() => onClickLink(section)}
                      >
                        {linkChildren}
                      </button>
                    )}
                {section.sections.map((item) =>
                  this.itemRenderer(item, level + 1, color),
                )}
              </ListWrapper>
            </UnmountClosed>
          )}
      </List>
    );
  };

  render() {
    const { visible, onClose, sections, siteUrl, theme, position } = this.props;
    const { active } = this.state;

    if (!active) {
      // portals are not supported serverside
      // whole point of "active" is, that when this component gets
      // server-rendered, it throws a nasty error about invalid
      // target element for rendering
      // it's hacky, but it works
      return null;
    }

    return ReactDOM.createPortal(
      <React.Fragment>
        <SidebarBackdrop $isOpen={visible!} onClick={onClose} />
        <Overlay $visible={visible} $position={position}>
          <ContentWrapper>
            <CloseButtonContainer>
              <CloseButtonFixed>
                <Title>{__('Menu')}</Title>
                <CloseButton onClick={onClose} $position={position}>
                  <CloseIcon
                    size={26}
                    name="close"
                    color={theme.responsiveMenu.closeButtonColor || '#fff'}
                  />
                </CloseButton>
              </CloseButtonFixed>
            </CloseButtonContainer>
            <MenuWrapper>
              <ListWrapper>
                {sections.map((item) =>
                  this.itemRenderer(item, 1, theme.responsiveMenu.linkColor),
                )}
              </ListWrapper>
              <Socials>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/grassrootsSFZ"
                >
                  <Social>{FacebookIcon(24)}</Social>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/grassroots_sfz"
                >
                  <Social>{InstagramIcon(24)}</Social>
                </a>
              </Socials>
            </MenuWrapper>
          </ContentWrapper>
          {siteUrl && <SiteUrl siteUrl={siteUrl} />}
        </Overlay>
        <GlobalStyle />
      </React.Fragment>,
      menuRoot,
    );
  }
}

export default withTheme(ResponsiveMenu);
