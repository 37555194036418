import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router';
import { getProp } from 'sportnet-utilities';
import { ReduxConnectProps, RootState } from '../../configureStore';
import AppSettings from '../../library/AppSettings';
import GlobalStyle from '../../theme/globalStyles';
import getDefaultLayoutFromSettings from '../../utilities/getDefaultLayoutFromSettings';
import getPageOrigin from '../../utilities/getPageOrigin';
import __ from '../../utilities/__';
import Loading from '../Loading';
import NotFoundPage from '../NotFoundPage';
import { loadAppSettings, setAppSettings } from './actions';
import Context from './context';
import {
  appSettingsDidFailSelector,
  appSettingsIsFetchingSelector,
  appSettingsSelector,
} from './selectors';

export const isStandalone =
  typeof window !== 'undefined' && window.location === window.parent.location;

const manifestTemplate = JSON.stringify({
  name: '{{NAME}}',
  short_name: '{{SHORT_NAME}}', // max 12 characters
  icons: [
    {
      src: '{{ORIGIN}}/android-chrome-192x192.png',
      sizes: '192x192',
      type: 'image/png',
    },
    {
      src: '{{ORIGIN}}/android-chrome-512x512.png',
      sizes: '512x512',
      type: 'image/png',
    },
  ],
  start_url: '{{ORIGIN}}',
  theme_color: '#ffffff',
  background_color: '#ffffff',
  display: 'standalone',
});

type OwnProps = { hostname?: string };

const mapStateToProps = (state: RootState) => {
  return {
    appSettings: appSettingsSelector(state),
    isFetching: appSettingsIsFetchingSelector(state),
    didFail: appSettingsDidFailSelector(state),
  };
};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReduxConnectProps &
  RouteProps<{}>;

interface IState {
  editModeEnabled: boolean;
}

class DomainResolver extends React.PureComponent<Props, IState> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    return dispatch(loadAppSettings.action());
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      editModeEnabled: false,
    };
    if (
      !isStandalone &&
      typeof window !== 'undefined' &&
      typeof window.addEventListener !== 'undefined'
    ) {
      window.addEventListener('message', this.handleMessage);
      this.postMessage('READY');
    }
  }

  postMessage = (type: string, payload = {}) => {
    window.parent.postMessage(
      {
        type,
        payload,
      },
      '*',
    );
  };

  handleMessage = (message: MessageEvent) => {
    const { dispatch } = this.props;
    console.log('[pages public] <<<', message.data);
    if (message.data.type === 'UPDATE_SETTINGS') {
      dispatch(setAppSettings({ settings: message.data.payload }));
    } else if (message.data.type === 'SET_EDIT_MODE') {
      this.setState({ editModeEnabled: message.data.payload });
    }
  };

  async componentDidMount() {
    if (isStandalone) {
      try {
        const appSettings = await DomainResolver.getInitialProps(this.props);
        this.createCustomManifest(appSettings);
      } catch (e: any) {
        /* */
      }
    }
  }

  createCustomManifest = (appSettings: AppSettings) => {
    if (typeof document !== 'undefined') {
      const defaultLayout = getDefaultLayoutFromSettings(appSettings);
      const title = getProp(defaultLayout, ['settings', 'pageTitle'], '');
      const stringManifest = manifestTemplate
        .replace(/{{NAME}}/g, title)
        .replace(/{{SHORT_NAME}}/g, title)
        .replace(/{{ORIGIN}}/g, getPageOrigin());
      const blob = new Blob([stringManifest], { type: 'application/json' });
      const manifestURL = URL.createObjectURL(blob);
      (document.querySelector('#manifest') as HTMLLinkElement).href =
        manifestURL;
    }
  };

  render() {
    const { appSettings, isFetching, didFail } = this.props;
    const { editModeEnabled } = this.state;

    const areSettingsLoaded = Object.keys(appSettings).length > 0;

    if (isFetching && !areSettingsLoaded) {
      return (
        <>
          <Helmet>
            <title>{__('Načítavam obsah')}</title>
          </Helmet>
          <GlobalStyle />
          <Loading />
        </>
      );
    }

    if (!areSettingsLoaded || didFail) {
      return (
        <>
          <Helmet>
            <title>{__('Stránka neexistuje')}</title>
          </Helmet>
          <GlobalStyle />
          <NotFoundPage />
        </>
      );
    }
    return (
      <Context.Provider
        value={{
          isStandalone,
          editModeEnabled,
          onClickArea: (id: string) => this.postMessage('CLICK_AREA', id),
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default connect(mapStateToProps)(DomainResolver);
