import Segment from '@sportnet/ui/Segment';
import { mb, mediaSizes } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';
import EntryAnimation from '../../components/EntryAnimation';
import config from '../../config';
import Page from '../../containers/Page';
import fluidTypography from '../../utilities/fluidTypography';

const Wrapper = styled.div`
  min-height: calc(100vh - ${rem(config.HEADER_HEIGHT)});
`;

const Content = styled.div`
  position: relative;
  margin: auto;
  max-width: ${rem(config.CONTENT_MAX_WIDTH)};
  padding: ${rem(40)} ${rem(8)} 0 ${rem(8)};
  ${mb('l')} {
    padding: ${rem(40)} ${rem(64)} 0 ${rem(64)};
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: ${rem(60)};
`;

const HeaderContainer = styled.div`
  margin: 0 ${rem(8)};
  max-width: calc(100% - ${rem(16)});
  width: calc(${rem(config.CONTENT_MAX_WIDTH)} - ${rem(16)});
  left: calc(50% - ${rem(8)});
  ${mb('l')} {
    margin: 0 ${rem(64)};
    max-width: calc(100% - ${rem(128)});
    width: calc(${rem(config.CONTENT_MAX_WIDTH)} - ${rem(128)});
    left: calc(50% - ${rem(64)});
  }
  position: absolute;
  background: #eee;
  border-radius: ${rem(4)};
  top: 50%;
  transform: translate(-50%, -50%);
  h1 {
    margin: 0;
    font-weight: 200;
    letter-spacing: -${rem(1)};
    ${fluidTypography(24, 32, mediaSizes.l, 1920)};
    color: #333;
    padding-left: ${rem(8)};
  }
`;

type OwnProps = {
  title: string;
  metaTitle?: string;
  children?: React.ReactNode;
};

const PublicPage: React.FC<OwnProps & RouteComponentProps<{}, {}>> = ({
  title,
  metaTitle,
  children,
  location,
}) => {
  return (
    <EntryAnimation key={title}>
      <Helmet>
        <title>{metaTitle || title}</title>
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/${location.pathname}`}
        />
        <meta property="og:title" content={metaTitle || title} />
        <meta property="og:type" content="section" />
      </Helmet>
      <Page>
        <HeaderWrapper>
          <HeaderContainer>
            <Segment raised noBottomGutter>
              <h1>{title}</h1>
            </Segment>
          </HeaderContainer>
        </HeaderWrapper>
        <Wrapper>
          <Content>{children}</Content>
        </Wrapper>
      </Page>
    </EntryAnimation>
  );
};

export default withRouter<OwnProps>(PublicPage);
