/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type RequestCreate_PoRegistry = {
  data: {
    source?: string;

    user: {
      email: string;

      name: string;

      surname: string;

      phone?: string;
    };

    data: {
      source: string;

      po: {
        appSpace?: string;

        organization: {
          full_name: string;

          name?: string;

          legal_form: string;

          business_id: string;

          vat_nr?: string;

          email?: string;

          www?: string;

          phone?: string;

          fax?: string;

          contact_person?: string;

          statutory?: string;
        };

        profile: {
          name: string;

          type: string;
        };

        addresses?: Array<{
          street?: string;

          number?: string;

          city?: string;

          zip?: string;

          country?: string;

          type?: string;
        }>;

        bank_account: {
          IBAN: string;
        };

        targetGroups?: Array<{
          targetGroupIdx: number;

          months: Array<string>;

          children_count?: string;
        }>;

        users?: Array<{
          sportnetId: string;

          name: string;

          license_type: string;

          license_id: string;

          shirt_size?: string;
        }>;
      };
    };
  };
};
export type TeamSquad = {
  _id: string;

  name: string;

  squad?: {
    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };
    }>;
  };
};
export type Team = {
  _id: string;

  appSpace: string;

  name: string;

  competitionId: string;

  partId: string;

  season: {
    name: string;
  };

  athletes?: Array<{
    playedMinutes?: number;

    sportnetUser: {
      _id: string;

      name: string;
    };

    additionalData: {
      birthdate: string;

      orgProfileName: string;

      gender: string;
    };
  }>;
};
export type TeamCreate = {
  name: string;
};
export type SportGround = {
  _id: string;

  name: string;

  geo: {
    type: string;

    coordinates: Array<number>;
  };
};
export type SportGroundCreate = {
  street: string;

  number?: string;

  zip: string;

  city: string;

  country: string;

  geo: {
    lat: number;

    lng: number;
  };
};
export type Event = {
  _id: string;

  name: string;

  startDate: string;

  endDate: string;

  sportGround: {
    name: string;

    geo: {
      coordinates: Array<number>;
    };
  };

  appSpace: string;

  description?: {
    widgets?: Array<{}>;
  };

  publication?: boolean;

  matchId: string;

  competitionId?: string;

  partId?: string;

  teams?: Array<{
    _id: string;
  }>;
};
export type Error = {
  statusCode: number;

  name: string;

  description: string;

  payload?: {};

  userinfo?: string;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class Api
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class Api {
  protected baseUrl: string = '';
  protected headers: { name: string; value: string }[] = [];
  protected token: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setHeaders(headers: { name: string; value: string }[]) {
    this.headers = headers;
  }

  public setToken(token: string) {
    this.token = token;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      } else if (responseContentType.includes('application/pdf')) {
        return response.blob();
      } else if (responseContentType.includes('text/plain')) {
        return response.text();
      }
      return {};
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   * Users list
   * @method
   * @name Api#getUsers
   */
  getUsers(
    project: string,
    parameters: {
      q?: string;
      relatedTo?: string;
    } = {},
  ): Promise<{
    users: Array<{
      _id: string;

      name: string;

      surname: string;

      sex?: 'M' | 'F';
    }>;

    limit: number;

    offset: number;

    total: number;
  }> {
    let path = '/public/{project}/users';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{project}', project.toString());

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['relatedTo'] !== 'undefined') {
      queryParameters['relatedTo'] = parameters['relatedTo'];
    }

    queryParameters['relatedTo'] = this.transformParameter(
      queryParameters['relatedTo'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * User detail
   * @method
   * @name Api#getUser
   */
  getUser(
    project: string,
    sportnetId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    surname: string;

    birthdate?: string;

    sex?: string;
  }> {
    let path = '/public/{project}/users/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{project}', project.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Organizations list
   * @method
   * @name Api#getPublicOrgranizations
   */
  getPublicOrgranizations(
    project: string,
    parameters: {
      seasonId: string;
    },
  ): Promise<{
    items: Array<{
      _id: string;

      name: string;

      type: string;
    }>;
  }> {
    let path = '/public/{project}/organizations';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{project}', project.toString());

    if (typeof parameters['seasonId'] !== 'undefined') {
      queryParameters['seasonId'] = parameters['seasonId'];
    }

    queryParameters['seasonId'] = this.transformParameter(
      queryParameters['seasonId'],
    );

    if (typeof parameters['seasonId'] === 'undefined') {
      throw new Error('Missing required parameter: seasonId');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get Events Count By Month and Year
   * @method
   * @name Api#getEventsCountByDate
   */
  getEventsCountByDate(
    project: string,
    parameters: {} = {},
  ): Promise<{
    eventsByDate: Array<{
      _id: string;

      count: number;
    }>;
  }> {
    let path = '/public/{project}/events-count';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get Teams Athletes Count in Season
   * @method
   * @name Api#getTeamsAthletesCount
   */
  getTeamsAthletesCount(
    project: string,
    seasonId: string,
    parameters: {} = {},
  ): Promise<{
    items: Array<{
      _id: string;

      appSpace: string;

      name: string;

      athletesCount: number;
    }>;
  }> {
    let path = '/public/{project}/athletes-count/{seasonId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{project}', project.toString());

    path = path.replace('{seasonId}', seasonId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Events Count In Season
   * @method
   * @name Api#getEventsCountBySeasonId
   */
  getEventsCountBySeasonId(
    project: string,
    seasonId: string,
    parameters: {} = {},
  ): Promise<{
    byAppSpace: {};
  }> {
    let path = '/public/{project}/events-count/{seasonId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{project}', project.toString());

    path = path.replace('{seasonId}', seasonId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get Seasons List
   * @method
   * @name Api#getPublicSeasons
   */
  getPublicSeasons(
    project: string,
    parameters: {} = {},
  ): Promise<{
    items: Array<{
      _id: string;

      name: string;
    }>;
  }> {
    let path = '/public/{project}/seasons';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get Published Events
   * @method
   * @name Api#publicGetEvents
   */
  publicGetEvents(
    project: string,
    parameters: {
      noLimit?: boolean;
      seasonId?: string;
      dateFrom?: string;
      dateTo?: string;
      appSpace?: string;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    events: Array<{
      _id: string;

      name: string;

      startDate: string;

      endDate: string;

      sportGround: {
        name: string;

        geo: {
          coordinates: Array<number>;
        };
      };

      appSpace: string;

      description?: {
        widgets?: Array<{}>;
      };

      publication?: boolean;

      matchId: string;

      competitionId?: string;

      partId?: string;

      teams?: Array<{
        _id: string;
      }>;
    }>;
  }> {
    let path = '/public/{project}/events';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['noLimit'] !== 'undefined') {
      queryParameters['noLimit'] = parameters['noLimit'];
    }

    queryParameters['noLimit'] = this.transformParameter(
      queryParameters['noLimit'],
    );

    if (typeof parameters['seasonId'] !== 'undefined') {
      queryParameters['seasonId'] = parameters['seasonId'];
    }

    queryParameters['seasonId'] = this.transformParameter(
      queryParameters['seasonId'],
    );

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    if (typeof parameters['appSpace'] !== 'undefined') {
      queryParameters['appSpace'] = parameters['appSpace'];
    }

    queryParameters['appSpace'] = this.transformParameter(
      queryParameters['appSpace'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get Published Event Detail
   * @method
   * @name Api#publicGetEvent
   */
  publicGetEvent(
    eventId: string,
    project: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    startDate: string;

    endDate: string;

    sportGround: {
      name: string;

      geo: {
        coordinates: Array<number>;
      };
    };

    appSpace: string;

    description?: {
      widgets?: Array<{}>;
    };

    publication?: boolean;

    matchId: string;

    competitionId?: string;

    partId?: string;

    teams?: Array<{
      _id: string;
    }>;
  }> {
    let path = '/public/{project}/events/{eventId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{eventId}', eventId.toString());

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get Team Nomination
   * @method
   * @name Api#publicGetEventNomination
   */
  publicGetEventNomination(
    project: string,
    eventId: string,
    parameters: {} = {},
  ): Promise<{
    appSpace: string;

    teamId: string;

    athletes: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData: {
        birthdate: string;

        orgProfileName: string;

        gender: string;
      };
    }>;
  }> {
    let path = '/public/{project}/events/{eventId}/nomination';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{project}', project.toString());

    path = path.replace('{eventId}', eventId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get AppSpace Seasons List
   * @method
   * @name Api#getAppSpaceSeasons
   */
  getAppSpaceSeasons(
    appSpace: string,
    project: string,
    parameters: {} = {},
  ): Promise<{
    items: Array<{
      _id: string;

      name: string;
    }>;
  }> {
    let path = '/admin/{appSpace}:{project}/seasons';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get AppSpace Events
   * @method
   * @name Api#adminGetEvents
   */
  adminGetEvents(
    appSpace: string,
    project: string,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    events: Array<{
      _id: string;

      name: string;

      startDate: string;

      endDate: string;

      sportGround: {
        name: string;

        geo: {
          coordinates: Array<number>;
        };
      };

      appSpace: string;

      description?: {
        widgets?: Array<{}>;
      };

      publication?: boolean;

      matchId: string;

      competitionId?: string;

      partId?: string;

      teams?: Array<{
        _id: string;
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}:{project}/events';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create New AppSpace Event
   * @method
   * @name Api#adminCreateEvent
   */
  adminCreateEvent(
    appSpace: string,
    project: string,
    parameters: {} = {},
    body: {
      seasonId: string;

      teamId: string;

      name: string;

      startDate: string;

      endDate: string;

      publication?: boolean;

      description?: {
        widgets?: Array<{}>;
      };

      sportGround: {
        _id: string;

        name?: string;
      };
    },
  ): Promise<{
    _id: string;

    name: string;

    startDate: string;

    endDate: string;

    sportGround: {
      name: string;

      geo: {
        coordinates: Array<number>;
      };
    };

    appSpace: string;

    description?: {
      widgets?: Array<{}>;
    };

    publication?: boolean;

    matchId: string;

    competitionId?: string;

    partId?: string;

    teams?: Array<{
      _id: string;
    }>;
  }> {
    let path = '/admin/{appSpace}:{project}/events';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get AppSpace Event
   * @method
   * @name Api#adminGetEventById
   */
  adminGetEventById(
    appSpace: string,
    project: string,
    eventId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    startDate: string;

    endDate: string;

    sportGround: {
      name: string;

      geo: {
        coordinates: Array<number>;
      };
    };

    appSpace: string;

    description?: {
      widgets?: Array<{}>;
    };

    publication?: boolean;

    matchId: string;

    competitionId?: string;

    partId?: string;

    teams?: Array<{
      _id: string;
    }>;
  }> {
    let path = '/admin/{appSpace}:{project}/events/{eventId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{eventId}', eventId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update AppSpace Event
   * @method
   * @name Api#adminUpdateEvent
   */
  adminUpdateEvent(
    appSpace: string,
    project: string,
    eventId: string,
    parameters: {} = {},
    body: {
      name: string;

      startDate: string;

      endDate: string;

      publication?: boolean;

      description?: {
        widgets?: Array<{}>;
      };

      sportGround: {
        _id: string;

        name?: string;
      };
    },
  ): Promise<{
    _id: string;

    name: string;

    startDate: string;

    endDate: string;

    sportGround: {
      name: string;

      geo: {
        coordinates: Array<number>;
      };
    };

    appSpace: string;

    description?: {
      widgets?: Array<{}>;
    };

    publication?: boolean;

    matchId: string;

    competitionId?: string;

    partId?: string;

    teams?: Array<{
      _id: string;
    }>;
  }> {
    let path = '/admin/{appSpace}:{project}/events/{eventId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{eventId}', eventId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete AppSpace Event
   * @method
   * @name Api#adminDeleteEvent
   */
  adminDeleteEvent(
    appSpace: string,
    project: string,
    eventId: string,
    parameters: {} = {},
  ): Promise<{
    message?: string;
  }> {
    let path = '/admin/{appSpace}:{project}/events/{eventId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{eventId}', eventId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get Team Nomination
   * @method
   * @name Api#adminGetEventNomination
   */
  adminGetEventNomination(
    appSpace: string,
    project: string,
    eventId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    athletes: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };
    }>;
  }> {
    let path =
      '/admin/{appSpace}:{project}/events/{eventId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{eventId}', eventId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update Team Nomination
   * @method
   * @name Api#adminUpdateEventNomination
   */
  adminUpdateEventNomination(
    appSpace: string,
    project: string,
    eventId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      athletes: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;
    },
  ): Promise<{
    athletes: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };
    }>;
  }> {
    let path =
      '/admin/{appSpace}:{project}/events/{eventId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{eventId}', eventId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * AppSpace sport grounds
   * @method
   * @name Api#adminGetSportGrounds
   */
  adminGetSportGrounds(
    appSpace: string,
    project: string,
    parameters: {} = {},
  ): Promise<{
    sportGrounds?: Array<{
      _id: string;

      name: string;

      geo: {
        type: string;

        coordinates: Array<number>;
      };
    }>;
  }> {
    let path = '/admin/{appSpace}:{project}/sport-grounds';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create DSG Sport Ground
   * @method
   * @name Api#adminCreateSportGround
   */
  adminCreateSportGround(
    appSpace: string,
    project: string,
    parameters: {} = {},
    body: {
      street: string;

      number?: string;

      zip: string;

      city: string;

      country: string;

      geo: {
        lat: number;

        lng: number;
      };
    },
  ): Promise<{
    _id: string;

    name: string;

    geo: {
      type: string;

      coordinates: Array<number>;
    };
  }> {
    let path = '/admin/{appSpace}:{project}/sport-grounds';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * AppSpace sport ground
   * @method
   * @name Api#adminGetSportGroundById
   */
  adminGetSportGroundById(
    appSpace: string,
    project: string,
    sportGroundId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    geo: {
      type: string;

      coordinates: Array<number>;
    };
  }> {
    let path = '/admin/{appSpace}:{project}/sport-grounds/{sportGroundId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{sportGroundId}', sportGroundId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * AppSpace teams
   * @method
   * @name Api#adminGetTeams
   */
  adminGetTeams(
    appSpace: string,
    project: string,
    parameters: {
      limit?: number;
      offset?: number;
      active?: boolean;
    } = {},
  ): Promise<{
    teams: Array<{
      _id: string;

      appSpace: string;

      name: string;

      competitionId: string;

      partId: string;

      season: {
        name: string;
      };

      athletes?: Array<{
        playedMinutes?: number;

        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData: {
          birthdate: string;

          orgProfileName: string;

          gender: string;
        };
      }>;
    }>;

    limit: number;

    offset: number;

    total: number;
  }> {
    let path = '/admin/{appSpace}:{project}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['active'] !== 'undefined') {
      queryParameters['active'] = parameters['active'];
    }

    queryParameters['active'] = this.transformParameter(
      queryParameters['active'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create AppSpace Team For Current Season Competition
   * @method
   * @name Api#adminCreateTeam
   */
  adminCreateTeam(
    appSpace: string,
    project: string,
    parameters: {} = {},
    body: {
      name: string;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    competitionId: string;

    partId: string;

    season: {
      name: string;
    };

    athletes?: Array<{
      playedMinutes?: number;

      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData: {
        birthdate: string;

        orgProfileName: string;

        gender: string;
      };
    }>;
  }> {
    let path = '/admin/{appSpace}:{project}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * AppSpace team detail
   * @method
   * @name Api#adminGetTeamById
   */
  adminGetTeamById(
    appSpace: string,
    project: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    competitionId: string;

    partId: string;

    season: {
      name: string;
    };

    athletes?: Array<{
      playedMinutes?: number;

      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData: {
        birthdate: string;

        orgProfileName: string;

        gender: string;
      };
    }>;
  }> {
    let path = '/admin/{appSpace}:{project}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update AppSpace Team
   * @method
   * @name Api#adminUpdateTeam
   */
  adminUpdateTeam(
    appSpace: string,
    project: string,
    teamId: string,
    parameters: {} = {},
    body: {
      name: string;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    competitionId: string;

    partId: string;

    season: {
      name: string;
    };

    athletes?: Array<{
      playedMinutes?: number;

      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData: {
        birthdate: string;

        orgProfileName: string;

        gender: string;
      };
    }>;
  }> {
    let path = '/admin/{appSpace}:{project}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete AppSpace Team
   * @method
   * @name Api#adminDeleteTeam
   */
  adminDeleteTeam(
    appSpace: string,
    project: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path = '/admin/{appSpace}:{project}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * AppSpace teams
   * @method
   * @name Api#publicGetTeams
   */
  publicGetTeams(
    appSpace: string,
    project: string,
    parameters: {
      seasonId?: string;
    } = {},
  ): Promise<{
    teams: Array<{
      _id: string;

      appSpace: string;

      name: string;

      competitionId: string;

      partId: string;

      season: {
        name: string;
      };

      athletes?: Array<{
        playedMinutes?: number;

        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData: {
          birthdate: string;

          orgProfileName: string;

          gender: string;
        };
      }>;
    }>;
  }> {
    let path = '/public/{appSpace}:{project}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['seasonId'] !== 'undefined') {
      queryParameters['seasonId'] = parameters['seasonId'];
    }

    queryParameters['seasonId'] = this.transformParameter(
      queryParameters['seasonId'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Player Events in Certain School
   * @method
   * @name Api#getPlayerEvents
   */
  getPlayerEvents(
    appSpace: string,
    project: string,
    sportnetId: string,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    events: Array<{
      _id: string;

      name: string;

      startDate: string;

      endDate: string;

      sportGround: {
        name: string;

        geo: {
          coordinates: Array<number>;
        };
      };

      appSpace: string;

      description?: {
        widgets?: Array<{}>;
      };

      publication?: boolean;

      matchId: string;

      competitionId?: string;

      partId?: string;

      teams?: Array<{
        _id: string;
      }>;
    }>;
  }> {
    let path = '/public/{appSpace}:{project}/player-events/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * All season teams
   * @method
   * @name Api#publicGetSeasonTeams
   */
  publicGetSeasonTeams(
    project: string,
    seasonId: string,
    parameters: {} = {},
  ): Promise<{
    teams: Array<{
      _id: string;

      appSpace: string;

      name: string;

      competitionId: string;

      partId: string;

      season: {
        name: string;
      };

      athletes?: Array<{
        playedMinutes?: number;

        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData: {
          birthdate: string;

          orgProfileName: string;

          gender: string;
        };
      }>;
    }>;
  }> {
    let path = '/public/{project}/season-teams/{seasonId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{project}', project.toString());

    path = path.replace('{seasonId}', seasonId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * AppSpace team squad in competition part
   * @method
   * @name Api#adminGetTeamSquad
   */
  adminGetTeamSquad(
    appSpace: string,
    project: string,
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    squad?: {
      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;
    };
  }> {
    let path =
      '/admin/{appSpace}:{project}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/players';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update AppSpace Team Squad
   * @method
   * @name Api#adminAddPlayerToTeamSquad
   */
  adminAddPlayerToTeamSquad(
    appSpace: string,
    project: string,
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      sportnetId: string;
    },
  ): Promise<{
    _id: string;

    name: string;

    squad?: {
      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;
    };
  }> {
    let path =
      '/admin/{appSpace}:{project}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/players';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * AppSpace team squad in competition part
   * @method
   * @name Api#adminRemovePlayerFromTeamSquad
   */
  adminRemovePlayerFromTeamSquad(
    appSpace: string,
    project: string,
    competitionId: string,
    partId: string,
    teamId: string,
    sportnetId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    squad?: {
      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;
    };
  }> {
    let path =
      '/admin/{appSpace}:{project}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/players/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * ACL
   * @method
   * @name Api#getAdminUserAcl
   */
  getAdminUserAcl(
    appSpace: string,
    project: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/admin/{appSpace}:{project}/acl';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Test Generate pdf
   * @method
   * @name Api#generatepdf
   */
  generatepdf(parameters: {} = {}): Promise<void> {
    let path = '/generate-pdf';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create New Po Registry Request
   * @method
   * @name Api#publicCreatePoRegistryRequest
   */
  publicCreatePoRegistryRequest(
    project: string,
    parameters: {} = {},
    body: {
      data: {
        source?: string;

        user: {
          email: string;

          name: string;

          surname: string;

          phone?: string;
        };

        data: {
          source: string;

          po: {
            appSpace?: string;

            organization: {
              full_name: string;

              name?: string;

              legal_form: string;

              business_id: string;

              vat_nr?: string;

              email?: string;

              www?: string;

              phone?: string;

              fax?: string;

              contact_person?: string;

              statutory?: string;
            };

            profile: {
              name: string;

              type: string;
            };

            addresses?: Array<{
              street?: string;

              number?: string;

              city?: string;

              zip?: string;

              country?: string;

              type?: string;
            }>;

            bank_account: {
              IBAN: string;
            };

            targetGroups?: Array<{
              targetGroupIdx: number;

              months: Array<string>;

              children_count?: string;
            }>;

            users?: Array<{
              sportnetId: string;

              name: string;

              license_type: string;

              license_id: string;

              shirt_size?: string;
            }>;
          };
        };
      };
    },
  ): Promise<{
    message: string;
  }> {
    let path = '/public/{project}/create-po-registry-request';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{project}', project.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Process Request Status Change
   * @method
   * @name Api#processRequestStatusChange
   */
  processRequestStatusChange(
    requestId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path = '/po-registry/{requestId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{requestId}', requestId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new Api();
export { Api };
