import { getListResults } from '@sportnet/redux-list/ducks';
import { createSelector } from 'reselect';
import at from 'sportnet-utilities/lib/at';
import config from '../../config';
import { RootState } from '../../configureStore';
import { entitiesSelector } from '../App/selectors';

const eventDetailByIdDomain = (state: RootState) => state.detail.events;

const currentEventDetailDomain = (id: string) =>
  createSelector(eventDetailByIdDomain, (byId) => {
    const item = byId[id];
    if (item) {
      return item;
    }
    return null;
  });

export const currentEventIsFetchingSelector = (id: string) =>
  createSelector(currentEventDetailDomain(id), (domain) =>
    domain ? !!domain.isFetching : false,
  );

export const currentEventSelector = (id: string) =>
  createSelector(entitiesSelector, (entities) =>
    id !== null && id in entities.events ? entities.events[id] : null,
  );

export const listNominationSelector = createSelector(
  entitiesSelector,
  getListResults(config.NOMINATION_LIST_NAME),
  (entities, ids) => at(entities.users, ids || []),
);
