import Article from '@sportnet/ui/Article';
import ArticleList from '@sportnet/ui/ArticleList';
import Col, { Row } from '@sportnet/ui/Grid';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import TheSelectSimple from '@sportnet/ui/TheSelectSimple';
import { format, isSameDay } from 'date-fns';
import { rem } from 'polished';
import useQuery, {
  NumberParam,
  StringParam,
} from '@sportnet/query-hoc/useQuery';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { compose } from 'redux';
import { commit, getListTotal, isCommiting } from '@sportnet/redux-list/ducks';
import Api from '../../api/Api';
import PublicPage from '../../components/PublicPage';
import config from '../../config';
import { RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';
import { initializeOrSetListParams, updateEntities } from '../App/actions';
import UsersTable from '../Users/UsersTable';
import { loadOrganization } from './actions';
import AvatarsIcon from './Avatars';
import { Stat, StatCount, StatDesc, translateSchoolType } from './list';
import {
  currentOrganizationSelector,
  listEventsSelector,
  listSeasonsSelector,
  listTeamsSelector,
} from './selectors';

const mapStateToProps = (
  state: RootState,
  props: RouteProps<{ id: string }>,
) => ({
  organization: currentOrganizationSelector(props.params.id)(state),
  events: listEventsSelector(state),
  total: getListTotal(config.ORGANIZATION_EVENTS_LIST_NAME)(state),
  isFetchingEvents: isCommiting(config.ORGANIZATION_EVENTS_LIST_NAME)(state),
  seasons: listSeasonsSelector(state),
  teams: listTeamsSelector(state),
  isFetchingTeams: isCommiting(config.ORGANIZATION_TEAMS_LIST_NAME)(state),
});
const mapDispatchToProps = {
  loadOrganizationItem: loadOrganization.action,
  setList: initializeOrSetListParams.action,
  commitList: commit.action,
  updateEvents: updateEntities,
};

const OrganizationInfo = styled.div`
  display: flex;
  align-items: center;
`;

const OrganizationName = styled.div`
  font-size: ${rem(24)};
  font-weight: bold;
  padding-left: ${rem(16)};
`;

const OrganizationType = styled.div`
  font-size: ${rem(14)};
  font-weight: 200;
  display: block;
`;

const EventsWrapper = styled.div`
  width: 100%;
  section {
    grid-gap: ${rem(10)};
  }
`;
const Pager = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: ${rem(8)};
`;
const Page = styled.div<{ active?: boolean }>`
  width: ${rem(12)};
  height: ${rem(12)};
  cursor: pointer;
  background: ${({ theme, active }) => (active ? theme.color.primary : '#aaa')};
  margin: 0 ${rem(4)};
  border-radius: 100%;
`;

type Props = RouteProps<{ id: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const SchoolDetail: React.FC<Props> = ({
  params: { id },
  router,
  location: { search, pathname },
  loadOrganizationItem,
  organization,
  setList,
  commitList,
  updateEvents,
  events,
  total,
  seasons,
  isFetchingEvents,
  teams,
  isFetchingTeams,
}) => {
  React.useEffect(() => {
    loadOrganizationItem({ id });
  }, [id]);

  const [page, setPage] = React.useState(1);
  const [countersIsFetching, setCountersIsFetching] = React.useState(false);
  const [eventsCount, setEventsCount] = React.useState(0);
  const [athletesCount, setAthletesCount] = React.useState(0);

  const normalizeEvents = (data: any[]) => {
    return {
      entities: {
        events: data.reduce((acc, item) => {
          return { ...acc, [String(item._id)]: item };
        }, {}) as { [key: string]: any },
      },
      result: data.map((item) => item._id),
    };
  };

  const normalizeSeasons = (data: any[]) => {
    return {
      entities: {
        seasons: data.reduce((acc, item) => {
          return { ...acc, [String(item._id)]: item };
        }, {}) as { [key: string]: any },
      },
      result: data.map((item) => item._id),
    };
  };

  const normalizeTeams = (data: any[]) => {
    return {
      entities: {
        teams: data.reduce((acc, item) => {
          return { ...acc, [String(item._id)]: item };
        }, {}) as { [key: string]: any },
      },
      result: data.map((item) => item._id),
    };
  };

  const { query, setQuery: setParameter } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    {
      parameters: {
        offset: NumberParam(0),
        teamsSeason: StringParam(''),
        eventsSeason: StringParam(''),
      },
    },
  );

  const getEventsCountBySeasonId = React.useCallback(async () => {
    if (query.eventsSeason) {
      try {
        setCountersIsFetching(true);
        const { byAppSpace } = await Api.getEventsCountBySeasonId(
          config.PROJECT_NAME,
          query.eventsSeason as string,
        );
        setEventsCount((byAppSpace as any)[id] || 0);
        const { items: athletesItems } = await Api.getTeamsAthletesCount(
          config.PROJECT_NAME,
          query.eventsSeason as string,
        );
        const athletesItem = athletesItems.find((i) => i.appSpace === id);
        setAthletesCount(athletesItem ? athletesItem.athletesCount : 0);
      } catch (e: any) {
        // alert(__('Nepodarilo sa získať zoznam zapojených škôl'));
      } finally {
        setCountersIsFetching(false);
      }
    }
  }, [query.eventsSeason]);

  React.useEffect(() => {
    getEventsCountBySeasonId();
  }, [getEventsCountBySeasonId]);

  const onChangePage = (newPage: number) => {
    const offset = (newPage - 1) * config.ORGANIZATION_EVENTS_LIST_LIMIT;
    setPage(newPage);
    setParameter({ offset });
  };

  React.useEffect(() => {
    const newPage = query.offset / config.ORGANIZATION_EVENTS_LIST_LIMIT + 1;
    setPage(newPage);
  }, [query.offset]);

  React.useEffect(() => {
    setList({
      listName: config.SEASONS_LIST_NAME,
      params: {
        offset: 0,
      },
    });
    commitList({
      listName: config.SEASONS_LIST_NAME,
      load: async () => {
        try {
          const res = await Api.getPublicSeasons(config.PROJECT_NAME);
          const { result, entities } = normalizeSeasons(res.items);

          updateEvents(entities);
          if (!query.teamsSeason) {
            await getTeams(res.items[0]._id);
          } else {
            await getTeams(query.teamsSeason as string);
          }
          if (!query.eventsSeason) {
            await getEvents(res.items[0]._id);
            setParameter({ eventsSeason: res.items[0]._id });
          } else {
            await getEvents(query.eventsSeason as string);
          }
          return {
            results: result,
            total: 0,
          };
        } catch (e: any) {
          alert(__('Nepodarilo sa získať zoznam ročníkov'));
          return {
            results: [],
            total: 0,
          };
        }
      },
    });
  }, [id]);

  const getTeams = async (seasonId: string) => {
    setList({
      listName: config.ORGANIZATION_TEAMS_LIST_NAME,
      params: {
        seasonId,
        id,
      },
    });
    await commitList({
      listName: config.ORGANIZATION_TEAMS_LIST_NAME,
      load: async () => {
        try {
          const teamsRes = await Api.publicGetTeams(id, config.PROJECT_NAME, {
            seasonId,
          });
          const { result, entities } = normalizeTeams(teamsRes.teams);

          updateEvents(entities);
          return {
            results: result,
            total: 0,
          };
        } catch (e: any) {
          alert(__('Nepodarilo sa získať zoznam žiakov'));
          return {
            results: [],
            total: 0,
          };
        }
      },
    });
  };

  React.useEffect(() => {
    if (query.teamsSeason) {
      getTeams(query.teamsSeason as string);
    }
  }, [query.teamsSeason]);

  React.useEffect(() => {
    if (query.eventsSeason) {
      getEvents(query.eventsSeason as string);
    }
  }, [query.offset, query.eventsSeason]);

  const getEvents = async (season: string) => {
    setList({
      listName: config.ORGANIZATION_EVENTS_LIST_NAME,
      params: {
        ...query,
        appSpace: id,
      },
    });
    await commitList({
      listName: config.ORGANIZATION_EVENTS_LIST_NAME,
      load: async () => {
        try {
          const res = await Api.publicGetEvents(config.PROJECT_NAME, {
            appSpace: id,
            seasonId: season,
            offset: query.offset,
            limit: config.ORGANIZATION_EVENTS_LIST_LIMIT,
          });
          const { result, entities } = normalizeEvents(res.events);

          updateEvents(entities);
          return {
            results: result,
            total: res.total,
          };
        } catch (e: any) {
          alert(__('Nepodarilo sa získať zoznam športových udalostí'));
          return {
            results: [],
            total: 0,
          };
        }
      },
    });
  };

  const pages = Math.ceil((total || 0) / config.ORGANIZATION_EVENTS_LIST_LIMIT);

  const athletes = teams.reduce(
    (acc: any[], t) => [
      ...acc,
      ...(t.athletes || []).map((a: any) => ({
        ...a.sportnetUser,
        ...a.additionalData,
      })),
    ],
    [],
  );

  const title = __('Informácie o škole');

  return (
    <PublicPage
      title={title}
      metaTitle={organization ? organization.name : __('Informácie o škole')}
    >
      {organization && (
        <>
          <Segment raised>
            <OrganizationInfo>
              <div>
                <AvatarsIcon />
              </div>
              <OrganizationName>
                <div>{organization.name}</div>
                <OrganizationType>
                  {translateSchoolType(organization.type)}
                </OrganizationType>
              </OrganizationName>
            </OrganizationInfo>
          </Segment>
          <Row>
            <Col xs={12} m={6}>
              <Segment raised loading={countersIsFetching}>
                <Stat>
                  <StatCount>{athletesCount}</StatCount>
                  <StatDesc>{__('zapojených detí')}</StatDesc>
                </Stat>
              </Segment>
            </Col>
            <Col xs={12} m={6}>
              <Segment raised loading={countersIsFetching}>
                <Stat>
                  <StatCount>{eventsCount}</StatCount>
                  <StatDesc>{__('zrealizovaných aktivít')}</StatDesc>
                </Stat>
              </Segment>
            </Col>
          </Row>
          <EventsWrapper>
            <Segment
              header={
                <SegmentHeader size="s" withSeparator>
                  {
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div>{__('Zrealizované aktivity')}</div>
                      <div>
                        {!!window.matchMedia && (
                          <TheSelectSimple
                            clearable={false}
                            value={
                              (query.eventsSeason as string) ||
                              (seasons.length ? seasons[0]._id : null)
                            }
                            onChange={(i) =>
                              setParameter({
                                eventsSeason: i as string,
                                offset: 0,
                              })
                            }
                            options={seasons.map((s) => ({
                              label: s.name,
                              value: s._id,
                            }))}
                          />
                        )}
                      </div>
                    </div>
                  }
                </SegmentHeader>
              }
              raised
              loading={!!isFetchingEvents}
            >
              <ArticleList>
                {events.map((e) => {
                  let imgSrc = '/logo.png';
                  if (e.description && (e.description.widgets || []).length) {
                    const imgWidget = e.description.widgets.find(
                      (w: any) => w.type === 'photo',
                    );
                    if (imgWidget && imgWidget.picture) {
                      imgSrc = imgWidget.picture.public_url;
                    } else if (imgWidget && imgWidget.uri) {
                      imgSrc = `https://mediamanager.sportnet.online/images/230x172/${imgWidget.uri}`;
                    }
                  }
                  return (
                    <Link
                      key={e._id}
                      to={`/skola/${e.appSpace}/aktivita/${e._id}`}
                    >
                      <Article
                        imgSrc={imgSrc}
                        title={`${e.name}`}
                        subtitle={
                          (
                            <>
                              {e.sportGround.name}
                              <br />
                              {`${format(
                                new Date(e.startDate),
                                config.DATE_TIME_FORMAT,
                              )} - ${format(
                                new Date(e.endDate),
                                isSameDay(e.startDate, e.endDate)
                                  ? 'HH:mm'
                                  : config.DATE_TIME_FORMAT,
                              )}`}
                            </>
                          ) as any
                        }
                      />
                    </Link>
                  );
                })}
              </ArticleList>
              {pages > 1 && (
                <Pager>
                  {Array(pages)
                    .fill(0)
                    .map((i, idx) => {
                      return (
                        <Page
                          key={`pager_${idx}`}
                          onClick={() => onChangePage(idx + 1)}
                          active={idx + 1 === page}
                        />
                      );
                    })}
                </Pager>
              )}
            </Segment>
          </EventsWrapper>
          <Segment
            raised
            loading={!!isFetchingTeams}
            header={
              <SegmentHeader size="s" withSeparator>
                {
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>{__('Zoznam žiakov')}</div>
                    <div>
                      {!!window.matchMedia && (
                        <TheSelectSimple
                          clearable={false}
                          value={
                            (query.teamsSeason as string) ||
                            (seasons.length ? seasons[0]._id : null)
                          }
                          onChange={(i) =>
                            setParameter({ teamsSeason: i as string })
                          }
                          options={seasons.map((s) => ({
                            label: s.name,
                            value: s._id,
                          }))}
                        />
                      )}
                    </div>
                  </div>
                }
              </SegmentHeader>
            }
          >
            <UsersTable
              items={
                [...(athletes as any)].map((i) => ({
                  ...i,
                  orgProfileId: id,
                  orgProfileName: organization.name,
                })) as any
              }
            />
          </Segment>
        </>
      )}
    </PublicPage>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SchoolDetail,
);
