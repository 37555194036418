import React from 'react';

const SchoolIcon = (size: number = 24, color: string = '#000') => {
  return (
    <svg
      version="1.1"
      id="child"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      width={size}
      height={size}
    >
      <path
        fill={color}
        d="M13,16.5l6-3.3V16c0,0.7-0.4,1.4-1,1.8l-5,2.7c-0.6,0.3-1.3,0.3-1.9,0l-5-2.7c-0.6-0.4-1-1-1-1.8v-2.8l6,3.3
	C11.6,16.8,12.4,16.8,13,16.5z M22.5,8.7L13,3.5c-0.6-0.3-1.3-0.3-1.9,0L2.6,8.1C2.1,8.4,1.9,9,2.2,9.5c0.1,0.2,0.2,0.3,0.4,0.4
	l8.4,4.6c0.6,0.3,1.3,0.3,1.9,0l8-4.4V16c0,0.6,0.4,1,1,1s1-0.4,1-1V9.6C23,9.2,22.8,8.9,22.5,8.7z"
      />
    </svg>
  );
};

export default SchoolIcon;
