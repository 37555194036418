import { createSelector } from 'reselect';
import { RootState } from '../../configureStore';

// Entities
export const entitiesSelector = (state: RootState) => {
  return state.entities;
};

const applicationDomainSelector = (state: RootState) => {
  return state.application;
};

const activeAppspaceIdSelector = createSelector(
  applicationDomainSelector,
  substate => substate.activeAppspace,
);

const appspacesSelector = createSelector(
  applicationDomainSelector,
  substate => substate.appspaces,
);

export const activeAppspaceSelector = createSelector(
  appspacesSelector,
  activeAppspaceIdSelector,
  (appspaces, activeId) => {
    if (activeId && appspaces) {
      const ppo = appspaces.find(appspace => {
        return appspace.app_space === activeId;
      });
      return ppo;
    }
    return null;
  },
);

export const activeAppspaceNameSelector = createSelector(
  activeAppspaceSelector,
  substate => {
    if (substate) {
      return substate.org_profile.name;
    }
    return null;
  },
);

export const activeAppspaceProfileSelector = createSelector(
  activeAppspaceSelector,
  substate => {
    if (substate) {
      return substate.org_profile;
    }
    return null;
  },
);

export const authUserSelector = createSelector(
  applicationDomainSelector,
  substate => substate.authUser,
);
