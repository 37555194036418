import { AnyAction, applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware, { ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import Api from './api/Api';
import CmsApi from './api/CmsApi';
import CoreApi from './api/CoreApi';
import PagesApi from './api/PagesApi';
import rootReducer from './rootReducer';

if (process.env.REACT_APP_API_BASE_URL) {
  Api.setBaseUrl(String(process.env.REACT_APP_API_BASE_URL));
} else {
  throw Error('REACT_APP_API_BASE_URL not set');
}

if (process.env.REACT_APP_CORE_API_BASE_URL) {
  CoreApi.setBaseUrl(String(process.env.REACT_APP_CORE_API_BASE_URL));
} else {
  throw Error('REACT_APP_CORE_API_BASE_URL not set');
}

if (process.env.REACT_APP_CMS_API_BASE_URL) {
  CmsApi.setBaseUrl(String(process.env.REACT_APP_CMS_API_BASE_URL));
} else {
  throw Error('REACT_APP_CMS_API_BASE_URL not set');
}

if (process.env.REACT_APP_PAGES_API_BASE_URL) {
  PagesApi.setBaseUrl(String(process.env.REACT_APP_PAGES_API_BASE_URL));
} else {
  throw Error('REACT_APP_PAGES_API_BASE_URL not set');
}

let composeEnhancers;
if (typeof window !== 'undefined') {
  const devToolsCompose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  composeEnhancers = devToolsCompose
    ? devToolsCompose({
        trace: true,
        traceLimit: 25,
      })
    : compose;
} else {
  composeEnhancers = compose;
}

interface IRootState extends ReturnType<typeof rootReducer> {}
export type RootState = IRootState;

const extraArgument = { Api, CoreApi };
export type ExtraArgumentType = typeof extraArgument;
const thunk: ThunkMiddleware<
  {},
  AnyAction,
  { Api: typeof Api; CoreApi: typeof CoreApi }
> = thunkMiddleware.withExtraArgument(extraArgument);

const enhancer = composeEnhancers(applyMiddleware(thunk));

export type CustomThunkAction<R> = (
  dispatch: ThunkDispatch<RootState, ExtraArgumentType, AnyAction>,
  getState: () => RootState,
  extraArgument: ExtraArgumentType,
) => R;

export type CustomThunkDispatch = ThunkDispatch<
  RootState,
  ExtraArgumentType,
  AnyAction
>;

export interface ReduxConnectProps {
  dispatch: CustomThunkDispatch;
}

export default function configureStore(initialState?: object) {
  return createStore(rootReducer, initialState!, enhancer);
}
