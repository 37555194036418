import {
  getListParameters,
  initialize,
  ListParameters,
  setParams,
} from '@sportnet/redux-list/ducks';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import {
  CustomThunkDispatch,
  ExtraArgumentType,
  RootState,
} from '../../configureStore';
import { IAuthorizationResponse, Writeable } from '../../library/App';
import updateEntitiesUtility from '../../utilities/updateEntities';
import { entitiesSelector } from './selectors';

const create = actionCreatorFactory('APP');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export interface Appspace {
  app_id: string;
  app_space: string;
  display_name: string;
  grant: boolean;
  org_profile: {
    name: string;
    organization_id: string;
    organization_name: string;
    sport: string;
    sport_sector: string;
    type: string;
    _id: string;
  };
  role: string;
  user_id: string;
}

export const setAppspaces = create<Appspace[]>('SET_APPSPACES');

export const setActiveAppspace = create<string>('SET_ACTIVE_APPSPACE');

export const removeActiveAppspace = create('REMOVE_ACTIVE_APPSPACE');

export const setAuthUser = create<IAuthorizationResponse>('SET_AUTH_USER');

export const initializeOrSetListParams = createAsync<
  {
    listName: string;
    params: ListParameters;
  },
  any
>('INITIALIZE_OR_SET_LIST_PARAMS', async (parameters, dispatch, getState) => {
  const reduxListParams = getListParameters(parameters.listName)(getState());
  if (Object.keys(reduxListParams).length === 0) {
    return dispatch(
      initialize({
        listName: parameters.listName,
        initialParams: parameters.params,
      }),
    );
  }
  return dispatch(
    setParams({
      listName: parameters.listName,
      parameters: parameters.params,
    }),
  );
});

export const updateEntities = (
  entitiesToUpdate: Partial<RootState['entities']>,
) => {
  const entities = entitiesToUpdate as Writeable<typeof entitiesToUpdate>;
  return (dispatch: CustomThunkDispatch, getState: () => RootState) => {
    const oldEntities = entitiesSelector(getState());
    const nextEntities = updateEntitiesUtility(oldEntities, entities);
    return dispatch({
      type: 'UPDATE_ENTITIES',
      payload: {
        result: {
          entities: nextEntities,
        },
      },
    });
  };
};

export const replaceEntities = (entities: Partial<RootState['entities']>) => {
  return {
    type: 'REPLACE_ENTITIES',
    payload: {
      result: {
        entities,
      },
    },
  };
};
