// App related config
export default {
  APP_ID: process.env.REACT_APP_APP_ID || 'dajmespolugol',
  PROJECT_NAME: process.env.REACT_APP_APP_ID || 'dajmespolugol',
  HEADER_HEIGHT: 100,
  MOBILE_HEADER_HEIGHT: 56,
  MAX_WIDTH: 1472,
  CONTENT_MAX_WIDTH: 1472,
  WIDER_CONTENT_MAX_WIDTH: 1472,
  DATE_TIME_FORMAT: 'dd.MM.yyyy HH:mm',
  OUTPUT_DATE_FORMAT: "yyyy-MM-dd'T'HH:mm:ssXX",
  HOME_EVENTS_LIST_NAME: 'HOME_EVENTS_LIST',
  ADMIN_EVENTS_LIST_NAME: 'ADMIN_EVENTS_LIST',
  ADMIN_EVENTS_LIST_LIMIT: 20,
  ORGANIZATION_EVENTS_LIST_NAME: 'ORGANIZATION_EVENTS_LIST',
  ORGANIZATION_EVENTS_LIST_LIMIT: 4,
  USER_EVENTS_LIST_NAME: 'USER_EVENTS_LIST',
  USER_EVENTS_LIST_LIMIT: 4,
  SEASONS_LIST_NAME: 'SEASONS_LIST',
  SEASONS_LIST_LIMIT: 0,
  ORGANIZATION_TEAMS_LIST_NAME: 'ORGANIZATION_TEAMS_LIST',
  ORGANIZATIONS_LIST_NAME: 'ORGANIZATIONS_LIST',
  USERS_LIST_NAME: 'USERS_LIST',
  NOMINATION_LIST_NAME: 'NOMINATION_LIST',
  ORGANIZATION_LIST_LIMIT: 0,
  USERS_LIST_LIMIT: 0,
  ADMIN_SPORTGROUNDS_LIST_NAME: 'ADMIN_SPORTGROUNDS_LIST',
  ADMIN_SPORTGROUNDS_LIST_LIMIT: 20,
  ADMIN_TEAMS_LIST_NAME: 'ADMIN_TEAMS_LIST',
  ADMIN_TEAMS_LIST_LIMIT: 20,
};
