import { reducerWithInitialState } from 'typescript-fsa-reducers';
import AppSettings from '../../library/AppSettings';
import * as actions from './actions';

export interface IAppSettingsState {
  isFetching?: boolean;
  error?: any | null;
  data?: AppSettings;
}

export const appSettingsReducer = reducerWithInitialState<IAppSettingsState>({})
  .case(
    actions.loadAppSettings.async.started,
    (state): IAppSettingsState => ({
      ...state,
      isFetching: true,
    }),
  )
  .case(
    actions.loadAppSettings.async.done,
    (state, { result }): IAppSettingsState => ({
      ...state,
      isFetching: false,
      error: null,
      data: result,
    }),
  )
  .case(
    actions.loadAppSettings.async.failed,
    (state, { error }): IAppSettingsState => ({
      ...state,
      isFetching: false,
      error,
    }),
  )
  .case(
    actions.setAppSettings,
    (state, payload): IAppSettingsState => ({
      data: payload.settings,
      isFetching: false,
      error: null,
    }),
  );
