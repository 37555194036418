import Cookies from 'js-cookie';
import * as React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import styled, { withTheme } from 'styled-components';
import CoreApi from '../../api/CoreApi';
import { ReduxConnectProps, RootState } from '../../configureStore';
import Analytics from '../../containers/Analytics';
import { ITheme } from '../../theme/theme';
import { setAuthUser } from './actions';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

type OwnProps = {
  theme: ITheme;
  children?: React.ReactNode;
};

const mapStateToProps = (state: RootState) => ({
  // appSettings: appSettingsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps &
  IMapStateToProps &
  RouteComponentProps<void, void> &
  ReduxConnectProps;

const App: React.FC<Props> = ({
  dispatch,
  children,
  location: { pathname },
  theme,
}) => {
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const accessToken = Cookies.get('access_token');
      if (accessToken) {
        CoreApi.setToken(accessToken);
        CoreApi.me().then((user) => {
          dispatch(setAuthUser({ user, accessToken }));
        });
      }
      try {
        window.scroll({ left: 0, top: 0, behavior: 'smooth' });
      } catch (e: any) {
        // fallback for browsers without support for ScrollToOptions
        window.scroll(0, 0);
      }
    }
  }, [pathname, dispatch]);

  return (
    <Wrapper>
      <Helmet titleTemplate="Dajme spolu gól - %s" />
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {children}
      </div>
    </Wrapper>
  );
};

export default compose(withTheme, connect(mapStateToProps))(App);
