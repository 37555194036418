import { createSelector } from 'reselect';
import { getProp } from 'sportnet-utilities';
import AppSettings from '../../library/AppSettings';
import { IAppSettingsState } from './reducer';

const appSettingsDomainSelector = (state: any) =>
  state.settings as IAppSettingsState;

export const appSettingsIsFetchingSelector = createSelector(
  appSettingsDomainSelector,
  domain => !!domain.isFetching,
);

export const appSettingsDidFailSelector = createSelector(
  appSettingsDomainSelector,
  domain => !!domain.error,
);

export const appSettingsSelector = createSelector(
  appSettingsDomainSelector,
  domain => getProp(domain, ['data'], {}) as Partial<AppSettings>,
);
