import * as PropTypes from 'prop-types';
import * as React from 'react';
import { rem } from 'polished';
import styled from '@sportnet/ui/Themes/styled-components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-self: flex-end;
  justify-content: center;
`;

const Footer = styled.div`
  text-align: center;
  font-size: ${rem(12)};
  color: ${({ theme }) => theme.responsiveMenu.siteUrlColor};
  letter-spacing: ${rem(1)};
  padding: ${rem(30)} 0;
  border-top: ${rem(3)} solid
    ${({ theme }) => theme.responsiveMenu.siteUrlBorder};
`;

interface Props {
  siteUrl: string;
}

const SiteUrl = (props: Props) => {
  return (
    <Wrapper>
      <Footer>{props.siteUrl}</Footer>
    </Wrapper>
  );
};

SiteUrl.propTypes = {
  siteUrl: PropTypes.string.isRequired,
};

export default SiteUrl;
