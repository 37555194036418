import { commit } from '@sportnet/redux-list/ducks';
import { formatUserName } from 'sportnet-utilities';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { ExtraArgumentType, RootState } from '../../configureStore';
import ForbiddenError from '../../ForbiddenError';
import InternalServerError from '../../InternalServerError';
import { NormalizedEntities } from '../../library/App';
import NotFoundError from '../../NotFoundError';
import UnauthorizedError from '../../UnauthorizedError';
import normalizeEntities from '../../utilities/normalizeEntities';
import __ from '../../utilities/__';
import { initializeOrSetListParams, updateEntities } from '../App/actions';
import { IUser } from './types';
const create = actionCreatorFactory('ARTICLE');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadUser = createAsync<
  { id: string; appSpace: string },
  NormalizedEntities<'users'>
>('LOAD_USER', async ({ id, appSpace }, dispatch, getState, { CoreApi }) => {
  try {
    const response = await CoreApi.organizationPPOUser(appSpace, id);
    return normalizeEntities('users', [
      {
        ...response,
        name: formatUserName(response),
        gender: response.sex,
      } as any,
    ]);
  } catch (e: any) {
    if (e && e.details && e.details.code === 401) {
      throw new UnauthorizedError(e);
    }
    if (e && e.details && e.details.code === 403) {
      throw new ForbiddenError(e);
    }
    if (e && e.details && e.details.code === 404) {
      throw new NotFoundError(e);
    }
    throw new InternalServerError(e);
  }
});

const normalizeUsers = (data: any[]) => {
  return {
    entities: {
      users: data.reduce((acc, item) => {
        return { ...acc, [String(item._id)]: item };
      }, {}) as { [key: string]: any },
    },
    result: data.map((item) => item._id),
  };
};

export const loadUsers = createAsync<
  { id: string },
  NormalizedEntities<'users'>
>('LOAD_USERS', async ({ id }, dispatch, getState, { Api }) => {
  try {
    dispatch(
      initializeOrSetListParams.action({
        listName: config.USERS_LIST_NAME,
        params: {
          id,
        },
      }),
    );
    let items: IUser[] = [];
    await dispatch(
      commit.action({
        listName: config.USERS_LIST_NAME,
        load: async () => {
          try {
            const { teams } = await Api.publicGetSeasonTeams(
              config.PROJECT_NAME,
              id,
            );
            items = teams
              .reduce(
                (acc, t) => [
                  ...acc,
                  ...(t.athletes || []).map((a) => ({
                    ...a,
                    orgProfileId: t.appSpace,
                  })),
                ],
                [],
              )
              .map(
                ({
                  sportnetUser,
                  additionalData,
                  orgProfileId,
                  playedMinutes,
                }) => ({
                  ...sportnetUser,
                  ...additionalData,
                  playedMinutes,
                  orgProfileId,
                }),
              );

            items = items.sort((a, b) => {
              return a.name > b.name ? 1 : -1;
            });

            const { result, entities } = normalizeUsers(items);

            updateEntities(entities);
            return {
              results: result,
              total: 0,
            };
          } catch (e: any) {
            alert(__('Nepodarilo sa získať zoznam žiakov'));
            return {
              results: [],
              total: 0,
            };
          }
        },
      }),
    );
    return normalizeEntities('users', items);
  } catch (e: any) {
    if (e && e.details && e.details.code === 401) {
      throw new UnauthorizedError(e);
    }
    if (e && e.details && e.details.code === 403) {
      throw new ForbiddenError(e);
    }
    throw new InternalServerError(e);
  }
});
